export const appConfig = {
  projectName: "lewis",
  mainApiUrl:
    // lewis01prod
    "https://ort9u3h654.execute-api.ap-northeast-2.amazonaws.com/prod/adminMain",
  testApiUrl:
    // itne_ai prod
    "https://qifabwlfbh.execute-api.ap-northeast-2.amazonaws.com/prod/adminMain",
  sessionName: "lewisuserss", // 사이트마다 달라야 함
  rememberidSSName: "lws0rmbrid", // 사이트마다 달라야 함
  showTerms: false, // true : 사용자로그인, false : H-ART 로그인
  defaultCompany: {
    name: "현대백화점",
    url: "https://ehyundai.ai-lewis.com",
  },
  companiesProd: [
    {
      name: "현대백화점",
      url: "https://ehyundai.ai-lewis.com",
    },
    {
      name: "현대홈쇼핑",
      url: "https://hmall.ai-lewis.com",
    },
    {
      name: "현대디에프",
      url: "https://eddy.ai-lewis.com",
    },
    {
      name: "현대그린푸드",
      url: "https://greenfood.ai-lewis.com",
    },
    {
      name: "현대지에프홀딩스",
      url: "https://greenfood.ai-lewis.com",
    },
  ],
  companiesDev: [
    {
      name: "현대백화점",
      url: "https://test1.ai-lewis.com",
    },
    {
      name: "현대홈쇼핑",
      url: "https://hmall.ai-lewis.com",
    },
    {
      name: "현대디에프",
      url: "https://eddy.ai-lewis.com",
    },
    {
      name: "현대그린푸드",
      url: "https://greenfood.ai-lewis.com",
    },
    {
      name: "현대지에프홀딩스",
      url: "https://greenfood.ai-lewis.com",
    },
  ],
};
