import { HttpAdminMainApi } from "@app/interface/admin-main-api";
import React, { useState, CSSProperties, useEffect } from "react";
import { userState } from "@app/interface/MainInterface";

// 각각의 컴포넌트에서 조회하면 됨
interface propsType {
  titleInfo: {
    title: string;
    answer: string;
    author: string;
    askTime: string;
  };
}
// 프롭스로 그냥 전체 받아서 내용을 던져주면 될 듯?
// QnADetail의 헤더
const QnABox = (props: propsType) => {
  // 그냥 타이틀 정보만 넘겨주면 될 듯
  const [titleInfo, setTitleInfo] = useState(props.titleInfo);
  return (
    <tr className="timeline-react">
      <th className="state" style={{ width: "10%" }}>
        {titleInfo.answer === "Y" ? "답변완료" : "답변예정"}
      </th>
      <th className="title" style={{ width: "65%" }}>
        {titleInfo.title}
      </th>
      <th className="name" style={{ width: "10%" }}>
        {titleInfo.author}
      </th>
      <th className="date" style={{ width: "15%" }}>
        {titleInfo.askTime}
      </th>
    </tr>
  );
};

export default QnABox;
