/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, CSSProperties, useEffect } from "react";
import { ContentHeader } from "@components";
import {
  HttpAdminMainApi,
  GetHistoryListParam,
  GetGtpTotalParam,
  GetMyHistoryListParam,
} from "@app/interface/admin-main-api";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import NoneHistoryBox from "@app/components/history-box/NoneHistoryBox";
import HistoryBox from "@app/components/history-box/HistoryBox";
import { userState } from "@app/interface/MainInterface";
import ClockLoader from "react-spinners/ClockLoader";
import NoneTotalListBox from "@app/components/total-box/NoneTotalListBox";
import TotalListBox from "@app/components/total-box/TotalListBox";
import LoadingBar from "@app/components/loading-bar/LoadingBar";
import moment from "moment";
interface propsType {
  userState: userState;
}

const Copy03 = (props: propsType) => {
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  let [isLoading, setLoading] = useState(false);
  const adminApi = new HttpAdminMainApi();
  //로그인 정보 (임시))
  const [userState, setUserState] = useState(props.userState);
  //입력값
  const [value, setValue] = useState(null);
  const [dataText, setDataText] = useState("");
  const [rangeText, setRangeText] = useState("");
  const [joinText, setJoinText] = useState("");
  const [multipleText, setMultipleText] = useState("0"); //0,1로 다른 학습결과 보여줌. 0만 있어야함 (추후 개선하자)
  //최대 3개월
  const [toDateText, setToDateText] = useState("");
  const [fromDateText, setFromDateText] = useState("");
  //결과값
  const [historyList, setHistoryList] = useState<any>([]);
  const [myHistoryList, setMyHistoryList] = useState<any>([]);
  const [gptTotalList, setGptTotalList] = useState<any>([]);
  //더보기
  const [historyAddList, setHistoryAddList] = useState(1);
  const [myHistoryAddList, setMyHistoryAddList] = useState(1);
  //Box 초기 세팅 (여기선 사용안함))
  const [historyShow, setHistoryShow] = useState(false);
  const [myHistoryShow, setMyHistoryShow] = useState(false);
  const [totalListShow, setTotalListShow] = useState(false);
  //결과값 제목들 히든처리
  const [result01Show, setResult01ShowShow] = useState(false);

  //History 조회 (이름)
  const get_history_list = async () => {
    if (dataText == "") {
      alert("제품 / 브랜드 값은 필수입니다.");
      return;
    }
    const param: GetHistoryListParam = {
      userState: props.userState,
      dataText: dataText,
      fromDateText: fromDateText,
      toDateText: toDateText,
    };
    setLoading(true);
    const res = await adminApi.get_history_list(param);
    if ("" + res.code === "200") {
      //네임 보여주기
      setResult01ShowShow(true);
      setHistoryAddList(3);
      setHistoryList(res.response.data_lists);
    } else {
      console.error("get_history_list ERROR : ", res.response.error_msg);
    }
    setLoading(false);
  };
  //my history
  const get_my_history_list = async () => {
    const param: GetMyHistoryListParam = {
      userId: props.userState.id,
      dataText: dataText,
      fromDateText: fromDateText,
      toDateText: toDateText,
    };
    setLoading(true);
    const res = await adminApi.get_my_history_list(param);
    if ("" + res.code === "200") {
      setMyHistoryAddList(10);
      setMyHistoryList(res.response.data_lists);
    } else {
      console.error("get_my_history_list ERROR : ", res.response.error_msg);
    }
    setLoading(false);
  };
  //total 조회
  const get_gpt_total_info = async () => {
    if (dataText == "") {
      alert("제품 / 브랜드 값은 필수입니다.");
      return;
    }

    if (joinText == "") {
      alert("테마/키워드/시즌 값은 필수입니다.");
      return;
    }

    const timeoutId = setTimeout(() => {
      console.error("get_gpt_total_info ERROR : Timed out after 60 seconds");
      setLoading(false);
    }, 60000);

    const param: GetGtpTotalParam = {
      userState: props.userState,
      dataText: dataText,
      joinText: joinText,
      multipleText: multipleText,
      make_num: "2", //2개 생성
    };

    setLoading(true);
    const res = await adminApi.get_gpt_total_info(param);
    clearTimeout(timeoutId);
    if ("" + res.code === "200") {
      setGptTotalList(res.response.data_lists);
      setHistoryList([]);
      get_my_history_list();
      get_history_list();
    } else {
      setLoading(false);
      console.error("get_gpt_total_info ERROR : ", res.response.error_msg);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!props.userState.id) {
      window.location.href = "/login";
    }
  }, []); //[] 호출시마다 조회

  useEffect(() => {
    const today = new Date();
    const threeMonthsAgo = new Date(today.setMonth(today.getMonth() - 3));
    const formattedDate = moment(threeMonthsAgo).format("YYYY-MM-DD");
    setToDateText(moment(new Date()).format("YYYY-MM-DD"));
    setFromDateText(formattedDate);
  }, []); //[] 호출시마다 조회

  return (
    <div>
      <ContentHeader title="AI 카피라이터" />
      <section className="content">
        <Container fluid>
          <Row>
            <div className="col-md-12 pd-0 mb-4">
              <div className="card card-primary card-outline">
                <div className="card-header">
                  <h3 className="card-title">
                    <i className="fas fa-edit cont-tit cpwt" />
                    카피의 소재를 알려주세요
                  </h3>
                </div>
                <div className="card-body pad table-responsive bg-box">
                  <Row>
                    <Col md={5}>
                      <Form.Group>
                        <h4>
                          제품 / 브랜드
                          <i className="far fa-question-circle nav-icon product" />
                        </h4>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          size="lg"
                          id="dataText"
                          name="dataText"
                          type="text"
                          placeholder=""
                          disabled={isLoading}
                          onChange={(event) => setDataText(event.target.value)}
                          value={dataText}
                        />
                        <Form.Control
                          size="lg"
                          id="rangeText"
                          name="rangeText"
                          type="hidden"
                          onChange={(event) => setRangeText(event.target.value)}
                          value={rangeText}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={5}>
                      <Form.Group>
                        <h4>
                          테마 / 키워드 / 시즌
                          <i className="far fa-question-circle nav-icon keyword" />
                        </h4>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          size="lg"
                          id="joinText"
                          name="joinText"
                          type="text"
                          placeholder=""
                          disabled={isLoading}
                          onChange={(event) => setJoinText(event.target.value)}
                          value={joinText}
                          onKeyPress={(
                            event: React.KeyboardEvent<HTMLInputElement>
                          ) => {
                            if (event.key === "Enter") {
                              get_gpt_total_info();
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group>
                        <h4>&nbsp;</h4>
                      </Form.Group>
                      <Form.Group>
                        <Button
                          className="w-100"
                          variant={isLoading ? "secondary" : "outline-primary"}
                          disabled={isLoading}
                          onClick={() => {
                            get_gpt_total_info();
                          }}
                        >
                          만들어볼게요
                        </Button>
                      </Form.Group>
                      <Form.Control
                        size="lg"
                        id="fromDateText"
                        name="fromDateText"
                        type="hidden"
                        disabled
                        placeholder=""
                        value={fromDateText}
                      />
                      <Form.Control
                        size="lg"
                        id="toDateText"
                        name="toDateText"
                        type="hidden"
                        disabled
                        placeholder=""
                        value={toDateText}
                      />
                    </Col>
                  </Row>
                  <div className="notice">
                    <dl>
                      <dt>
                        <i
                          className="fa fa-exclamation-circle"
                          aria-hidden="true"
                        ></i>
                        루이스의 제안은 사실과 다를 수 있으니 꼭 확인해주세요
                      </dt>
                      <dd>(ex.가장 한국적인 브랜드, 루이비통)</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            {/* /.col */}
            {/* <div className="col-md-12 pd-0 mb-3"> */}
            <Col md={12} className="pd-0 mb-3">
              <div className="card card-primary card-outline">
                <div className="card-header">
                  {result01Show ? (
                    <h3 className="card-title">
                      <i className="fas fa-edit cont-tit result" />
                      Lewis는 제목과 본문을 이렇게 생각했어요
                    </h3>
                  ) : null}
                </div>
                {/* {totalShow && } */}
                <div className="row">
                  {totalListShow && <NoneTotalListBox />}
                  {totalListShow && <NoneTotalListBox />}
                  {gptTotalList.map((item: any, index: number) => (
                    <TotalListBox
                      key={item.totalKey.range}
                      userState={userState}
                      pk={item.totalKey.pk}
                      range={item.totalKey.range}
                      dataText={dataText}
                      joinText={joinText}
                    ></TotalListBox>
                  ))}
                </div>
              </div>
            </Col>
            {/* /.col */}
            {/* HISTORY */}
            {result01Show ? (
              <div className="col-md-12 mb-4">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="fas fa-edit cont-tit time-his" />
                      내가 만든 카피
                    </h3>
                  </div>
                  <div className="card-body pad table-responsive bg-box">
                    <div className="time-scroll-y">
                      {myHistoryShow && <NoneHistoryBox />}
                      {myHistoryList
                        .slice(0, myHistoryAddList)
                        .map((item: any, index: number) => (
                          <HistoryBox
                            key={item.range}
                            userState={userState}
                            pk={item.pk}
                            range={item.range}
                          ></HistoryBox>
                        ))}
                    </div>
                    <p className="more-btn-wrap">
                      <Button
                        variant="light"
                        onClick={() => {
                          setMyHistoryAddList(myHistoryAddList + 5);
                        }}
                      >
                        더보기 +
                      </Button>
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
            {/* /.col */}
            {/* /.col */}
            {/* HISTORY */}

            {result01Show ? (
              <div className="col-md-12 mb-4">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="fas fa-edit cont-tit time-wehis" />
                      다른사람들은 {dataText} ({joinText})에 대해서 이렇게
                      입력했어요
                    </h3>
                  </div>
                  <div className="card-body pad table-responsive bg-box">
                    <div className="time-scroll-y">
                      {historyShow && <NoneHistoryBox />}
                      {historyList
                        .slice(0, historyAddList)
                        .map((item: any, index: number) => (
                          <HistoryBox
                            key={item.main_range}
                            userState={userState}
                            pk={item.main_pk}
                            range={item.main_range}
                          ></HistoryBox>
                        ))}
                    </div>
                    <p className="more-btn-wrap">
                      <Button
                        variant="light"
                        onClick={() => {
                          setHistoryAddList(historyAddList + 5);
                        }}
                      >
                        더보기 +
                      </Button>
                    </p>
                  </div>
                </div>
              </div>
            ) : null}

            {/* /.col */}
          </Row>
        </Container>
      </section>
      {/* /.content */}
      <LoadingBar
        isLoading={isLoading}
        loadingTexts={[
          `루이스가 [${dataText}]에 대해서 카피를 생성하고 있어요!`,
          "잠시만 기다려주세요",
        ]}
        interval={150}
      />
    </div>
  );
};

export default Copy03;
