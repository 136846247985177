import React, { useState, CSSProperties, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ContentHeader } from "@components";
import {
  Button,
  Container,
  Form,
  Row,
  Col,
  InputGroup,
  Collapse,
  Table,
} from "react-bootstrap";
// import Table from "react-bootstrap/Table";
import { userState } from "@app/interface/MainInterface";
import { Thumbs } from "swiper";

import {
  HttpAdminMainApi,
  GetQnAListParam,
  GetQnACntParam,
} from "@app/interface/admin-main-api";

interface propsType {
  userState: userState;
}
// 1. 생성 버튼을 생성 -> ok
// 2. 생성 페이지 -> ok
// 3. 목록을 불러옴
// ddb에서 input_id로 조회해오기
// 4. 리스트 -> 게시판 구현
// 리스트에 넘겨줄때는 그냥 다 넘겨주고
// 클릭해서 들어가서 디테일을 확인하는 형태

const QnAList = (props: propsType) => {
  // console.log("props : " + JSON.stringify(props));
  const navigate = useNavigate();

  const { paging } = useParams();
  const [pages, setPages] = useState(Number(paging));
  // 사용자 정보
  const [userState, setUserState] = useState(props.userState);
  // qna 목록 보여주기
  const [qnaList, setQnaList] = useState([]);
  const [noanswerCnt, setNoanswerCnt] = useState(0);

  const adminApi = new HttpAdminMainApi();

  // qLength는 전체의 크기가 아님
  const [qLength, setQLength] = useState(0);

  const addButton = useRef(null);

  // qna Count를 가져옴과 동시에 페이징을 처리함
  const getQnaCnt = async () => {
    const param: GetQnACntParam = {
      userState,
    };
    const res = await adminApi.get_my_qna_cnt(param);
    setQLength(res.response.total_cnt[0]);
    setNoanswerCnt(res.response.noanswer_cnt[0]);
  };

  const getQnaList = async (nowPages: number) => {
    // api 호출해서 rds 타고 쿼리 조회해오기
    const param: GetQnAListParam = {
      userState: props.userState,
      page: nowPages,
    };
    const res = await adminApi.get_my_qna_list(param);
    addButton.current.style.visibility = "visible";
    if ("" + res.code === "200") {
      // 가져온 결과 셋팅
      setQnaList(res.response.qna_list);
    } else {
      alert("데이터를 가져오는데 실패하였습니다! 새로고침해주세요!");
      // console.log("devloping...");
    }
  };

  // 로그인 정보 없으면 로그인 페이지로 이동
  useEffect(() => {
    if (!props.userState.id) {
      window.location.href = "/login";
    }
    // 숫자가 아닐경우 0으로 자동 리다이렉팅하기
    if (isNaN(Number(paging))) {
      navigate("/qna/list/1");
    }
    setPages(Number(paging));
    getQnaList(pages);
    getQnaCnt();
  }, []);

  useEffect(() => {
    // paramter가 변경된것을 기반으로 state를 변경하고, 그 이후에 함수를 호출해서 값을 변경
    setPages(Number(paging));
  }, [paging]);

  useEffect(() => {
    // console.log("now page is : " + pages);
    getQnaList(pages);
  }, [pages]);

  // 상세 페이지로 이동
  const moveToDetail = (item: any) => {
    return navigate("/qna/detail", {
      state: {
        item,
        userState,
      },
    });
  };

  // 작성 페이지로 이동
  const moveToWritePage = () => {
    return navigate("/qna/write", {
      state: {
        userState,
        qLength,
      },
    });
  };

  return (
    <div>
      <ContentHeader title="문의" />
      <section className="content">
        <Container fluid>
          <Row>
            <div className="col-md-12 pd-0 mb-4">
              <div className="card card-primary card-outline">
                {/* 헤더 */}
                <div className="card-header">
                  <Row>
                    <Col>
                      <i className="fas fa-edit cont-tit qna"></i>
                      <h1 className="card-title">무엇을 도와드릴까요?</h1>
                    </Col>
                    <Col xs={2}>
                      <Button
                        onClick={moveToWritePage}
                        aria-controls="add_qna"
                        aria-expanded={open}
                        className="primary"
                        ref={addButton}
                        style={{
                          float: "right",
                          marginRight: "5px",
                          visibility: "hidden",
                        }}
                      >
                        문의 추가
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="card-body pad table-responsive bg-box">
                  <div className="qna-cont">
                    <div className="input-wrap">
                      <div className="form-group qna-list">
                        <h4 className="case-num">
                          <span>
                            총 문의 : <strong>{qLength}</strong>건
                          </span>
                          <span>
                            답변완료 : <strong>{qLength - noanswerCnt}</strong>
                            건
                          </span>
                          <span>
                            문의접수 : <strong>{noanswerCnt}</strong>건
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className="form-group">
                      <Table hover className="table">
                        <thead>
                          <tr>
                            <th
                              className="state unans"
                              style={{ width: "10%" }}
                            >
                              상태
                            </th>
                            <th className="title" style={{ width: "65%" }}>
                              제목
                            </th>
                            <th className="name" style={{ width: "10%" }}>
                              글쓴이
                            </th>
                            <th className="date" style={{ width: "15%" }}>
                              등록일
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* 여기서 이제 받아온 리스트를 박스형태로 보여주는 형식으로 만들어보자 */}
                          {/* 타이틀 정보 + 답변여부 + 질문직성시간 넘겨주기 */}
                          {qnaList.length > 0 ? (
                            qnaList.map((item: any) => (
                              <tr
                                key={item.QSEQ}
                                className="timeline-react"
                                onClick={() => moveToDetail(item)}
                              >
                                {item.ANSWER_YN === "N" ? (
                                  <td className="state unans">접수완료</td>
                                ) : (
                                  <td className="state">답변완료</td>
                                )}
                                {/* <td style>{answerCheck ? "답변완료" : "답변예정"}</td> */}
                                <td className="title">{item.TITLE}</td>
                                <td className="name">{item.AUTHOR}</td>
                                <td className="date">{item.CREATE_DATE}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4}>
                                아직 문의하신 내역이 없습니다.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <div className="page-wrap">
                        <ul>
                          <li>
                            <Button className="prev">
                              <i
                                className="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          </li>
                          <li>
                            {/* 클릭할때마다 active를 다르게 하고 10개씩 가져오기 */}
                            <a href="#" className="active">
                              1
                            </a>
                          </li>
                          <li>
                            <a href="#">2</a>
                          </li>
                          <li>
                            <a href="#">3</a>
                          </li>
                          <li>
                            <Button className="next">
                              <i
                                className="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default QnAList;
