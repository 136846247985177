import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { HttpAdminMainApi } from "@app/interface/admin-main-api";
import { userState } from "@app/interface/MainInterface";

const Landing = () => {
  const { uuid } = useParams();

  const [userState, setUserState] = useState<userState>({
    id: "",
    userName: "",
    userInfo1: "",
    userInfo2: "",
    userInfo3: "",
    userInfo4: "",
    userType: "",
  });

  // console.log("type uuid : " + typeof uuid);
  // 여기서 axios로 post를 날려서 다시한번 데이터를 가져옴
  const adminApi = new HttpAdminMainApi();
  const getUserInfo = async () => {
    const uuid_data: string = uuid;
    const res = await adminApi.get_user_info_uuid(uuid_data);
    if (!("result" in res.response) || res.response.result.length == 0) {
      // Result가 없다면 로그인 페이지로 포워딩
      alert("로그인 정보가 잘못 되었습니다. 다시 시도해주세요");
      window.location.href = "/login";
    }
    // console.log("result" in res.response);
    // 이게 잘못되면 로그인 화면으로 포워딩 시켜야 함 아니면 새로 고침 해달라고 이야기 해야함
    let userInfo: any = res.response.result[0];

    let userParam: userState = {
      id: "",
      userName: "",
      userInfo1: "",
      userInfo2: "",
      userInfo3: "",
      userInfo4: "",
      userType: "",
    };

    userParam.id = userInfo.user_id;
    userParam.userName = userInfo.user_name;
    let userInfoListData: Array<string> = userInfo.path.split(";");
    console.log(userInfoListData);
    if (userInfoListData.length < 5) {
      let lastValue: string = userInfoListData[userInfoListData.length - 1];
      for (let i = 0; i < 5 - userInfoListData.length; i++) {
        userInfoListData.push(lastValue);
      }
    }
    userParam.userInfo1 = userInfoListData[1];
    userParam.userInfo2 = userInfoListData[2];
    userParam.userInfo3 = userInfoListData[3];
    userParam.userInfo4 = userInfoListData[4];
    // 현재 하드코딩한 상태 수정 필요하면 수정하기
    userParam.userType = userInfo.user_type;
    setUserState(userParam);
    // 저장한 데이터를 세션에 넣고 이동
    const objString = JSON.stringify(userParam);
    window.localStorage.setItem("lewisuserss", objString);
    window.location.href = "/agree";
  };
  useEffect(() => {
    getUserInfo();
  }, []);
  return (
    <div>
    </div>
  );
};

export default Landing;
