import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

export default function NoneTotalListBox() {
  return (
    <div className="col-md-6">
      <div className="pad table-responsive">
        <div className="callout callout-info position-relative">
          <h5>&nbsp;</h5>
          <p>
          &nbsp;
          </p>
          <div className="result-btn-wrap">
          <h3>&nbsp;</h3> 
          </div>
          <div className="swiper mySwiper result-list">
            <div className="swiper-wrapper">
              
                  <div className="swiper-slide">
                    <div className="slide-cont">
                      <dl>
                        
                      </dl>
                      <h5>&nbsp;</h5>
                    </div>
                  </div>
                
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
