import axios from "axios";
import dayjs from "dayjs";
import { appConfig, setAccessToken } from "../config/Config";

export abstract class ApiBase {
  protected apiName: string;
  protected urlPath: string;

  abstract onApiName(): string;
  abstract onUrlPath(): string;

  constructor() {
    this.apiName = this.onApiName();
    this.urlPath = this.onUrlPath();
  }

  protected async requestPost(requestBody: any): Promise<any> {
    let header: any = {};

    // const response = await API.post(this.apiName, this.urlPath, request);
    const response = await axios.post(this.urlPath, requestBody, header);
    return response.data;
  }

  // protected async requestPostLewis(requestBody: any): Promise<any> {
  //   let header: any = {};
  //   let authToken: string;

  //   header = {
  //     "Content-Type": "application/json"
  //   };

  //   const opions = {
  //     headers: header,
  //   };
  //   const response = await axios.post(this.urlPath, requestBody, opions);
  //   return response;
  // }

  // protected async getExternalToken() {
  //   const exCred = await this.getAccessToken();
  //   return `Bearer ${exCred}`;
  // }

  // protected async getAccessToken(): Promise<any> {
  //   const basicHeader = btoa(`${appConfig.app_client_id}:${appConfig.app_client_secret}`);
  //   const reqBody = `grant_type=client_credentials`;
  //   let expireDiff = 0;
  //   if (appConfig.access_token_expire_time !== undefined) {
  //     expireDiff = dayjs().diff(appConfig.access_token_expire_time, "s");
  //   }

  //   if (appConfig.access_token === undefined || expireDiff > 0) {
  //     // 만료시간전에는 기존 Access Token을 보내자.
  //     // Cors 때문에 일반 fetch 못쓰고 axios 사용함....
  //     const response = await axios.post(appConfig.app_client_url, reqBody, {
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8;",
  //         Authorization: "Basic " + basicHeader,
  //       },
  //     });

  //     if (response.status === 200) {
  //       setAccessToken(response.data.access_token, dayjs().add(response.data.expires_in - 3, "s"));
  //     } else {
  //       return null;
  //     }
  //   }
  //   return appConfig.access_token;
  // }
}
