import React from "react";

export default function NoneHistoryBox() {
  return (
    <div className="timeline-react">
      <div id="swiper" className="swiper mySwiper result-list timeline-sl">
        <div className="swiper-wrapper">
          {/* timeline item */}
          <div className="swiper-slide">
            <div className="slide-cont">
              <span className="time">
                <i className="fas fa-clock" /> 12:05
              </span>
              <dl>
                <dt>슬램덩크</dt>
                <dd>
                  90년대 레전드 만화영화 '슬램덩크' 30주년 기념판 오리지널
                  감독판 개봉 확정!90년대 레전드 만화영화 '슬램덩크' 30주년
                  기념판 오리지널 감독판 개봉 확정! 30주년 기념판 오리지널
                  감독판 개봉 확정! 감독판 개봉 확정!
                </dd>
              </dl>
              <div className="kw-wrap">
                <span className="kw-tag">#극장판</span>
                <span className="kw-tag">#극장판</span>
              </div>
              <div className="slide-icon-wrap">
                <i className="fas fa-icon fa-clone" />
                <i className="fas fa-icon fa-thumbs-up" />
                <i className="fas fa-icon fa-thumbs-down" />
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-cont">
              <span className="time">
                <i className="fas fa-clock" /> 12:05
              </span>
              <dl>
                <dt>슬램덩크</dt>
                <dd>
                  90년대 레전드 만화영화 '슬램덩크' 30주년 기념판 오리지널
                  감독판 개봉 확정!90년대 레전드 만화영화 '슬램덩크' 30주년
                  기념판 오리지널 감독판 개봉 확정! 30주년 기념판 오리지널
                  감독판 개봉 확정! 감독판 개봉 확정!
                </dd>
              </dl>
              <div className="kw-wrap">
                <span className="kw-tag">#극장판</span>
                <span className="kw-tag">#극장판</span>
              </div>
              <div className="slide-icon-wrap">
                <i className="fas fa-icon fa-clone" />
                <i className="fas fa-icon fa-thumbs-up" />
                <i className="fas fa-icon fa-thumbs-down" />
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-button-next" />
        <div className="swiper-button-prev" />
        <div className="swiper-pagination" />
      </div>
    </div>
  );
}
