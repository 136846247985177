import React, { useState, useEffect } from "react";
import { ContentHeader } from "@components";

import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  Row,
  Col,
  InputGroup,
  Collapse,
  Table,
} from "react-bootstrap";
// import Table from "react-bootstrap/Table";
import { userState } from "@app/interface/MainInterface";
import { HttpAdminMainApi } from "@app/interface/admin-main-api";

interface propsType {
  userState: userState;
}
const QnAAdmin = (props: propsType) => {
  const [userState, setUserState] = useState(props.userState);

  const navigate = useNavigate();

  useEffect(() => {
    if (!props.userState.id) {
      window.location.href = "/login";
    }
    // 임시로 2를 넣어둠 나중에는 관리코드로 별도로 관리해야함
    if (props.userState.userType != "1") {
      navigate("/qna/list/1");
    }
  });

  const [qnaList, setQnaList] = useState([]);
  // 답변한 갯수 카운팅
  const [answerCnt, setAnswerCnt] = useState(0);
  let tempAnswerCnt = 0;

  const adminApi = new HttpAdminMainApi();

  const [qLength, setQLength] = useState(0);

  const getQnaList = async () => {
    // api 호출해서 rds 타고 쿼리 조회해오기
    const res = await adminApi.get_admin_qna_list(props.userState.userType);
    if ("" + res.code === "200") {
      // 가져온 결과 셋팅
      setQnaList(res.response.qna_list);
      setQLength(res.response.qna_list.length);
      tempAnswerCnt = 0;
      let tempTotalQnaList = res.response.qna_list;
      for (let i = 0; i < tempTotalQnaList.length; i++) {
        if (tempTotalQnaList[i].ANSWER_YN === "Y") {
          tempAnswerCnt++;
        }
      }
      setAnswerCnt(tempAnswerCnt);
    } else {
      alert("데이터를 가져오는데 실패하였습니다! 새로고침해주세요!");
      console.log("devloping...");
    }
  };

  // 로그인 정보 없으면 로그인 페이지로 이동
  useEffect(() => {
    if (!props.userState.id) {
      window.location.href = "/login";
    }
    getQnaList();
  }, []);

  // 상세 페이지로 이동
  const moveToDetail = (item: any) => {
    return navigate("/qna/detailAdmin", {
      state: {
        item,
        userState,
      },
    });
  };

  return (
    <div>
      <ContentHeader title="문의" />
      <section className="content">
        <Container fluid>
          <Row>
            <div className="col-md-12 pd-0 mb-4">
              <div className="card card-primary card-outline">
                {/* 헤더 */}
                <div className="card-header">
                  <Row>
                    <Col>
                      <i className="fas fa-edit cont-tit qna"></i>
                      <h1 className="card-title">문의 관리</h1>
                    </Col>
                  </Row>
                </div>
                <div className="card-body pad table-responsive bg-box">
                  <div className="qna-cont">
                    <div className="input-wrap">
                      <div className="form-group qna-list">
                        <h4 className="case-num">
                          <span>
                            총 문의 : <strong>{qnaList.length}</strong>건
                          </span>
                          <span>
                            답변완료 : <strong>{answerCnt}</strong>건
                          </span>
                          <span>
                            미답변 :{" "}
                            <strong>{qnaList.length - answerCnt}</strong>건
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className="form-group">
                      <Table hover className="table">
                        <thead>
                          <tr>
                            <th
                              className="state unans"
                              style={{ width: "10%" }}
                            >
                              상태
                            </th>
                            <th className="title" style={{ width: "65%" }}>
                              제목
                            </th>
                            <th className="name" style={{ width: "10%" }}>
                              글쓴이
                            </th>
                            <th className="date" style={{ width: "15%" }}>
                              등록일
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* 여기서 이제 받아온 리스트를 박스형태로 보여주는 형식으로 만들어보자 */}
                          {/* 타이틀 정보 + 답변여부 + 질문직성시간 넘겨주기 */}
                          {qnaList.length > 0 ? (
                            qnaList.map((item: any) => (
                              <tr
                                key={item.QSEQ}
                                className="timeline-react"
                                onClick={() => moveToDetail(item)}
                              >
                                {item.ANSWER_YN === "N" ? (
                                  <td className="state unans">답변필요</td>
                                ) : (
                                  <td className="state">답변완료</td>
                                )}
                                <td className="title">{item.TITLE}</td>
                                <td className="name">{item.AUTHOR}</td>
                                <td className="date">{item.CREATE_DATE}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4}>
                                답변이 필요한 문의 내역이 없습니다.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <div className="page-wrap">
                        <ul>
                          <li>
                            <Button className="prev">
                              <i
                                className="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          </li>
                          <li>
                            <a href="#" className="active">
                              1
                            </a>
                          </li>
                          <li>
                            <a href="#">2</a>
                          </li>
                          <li>
                            <a href="#">3</a>
                          </li>
                          <li>
                            <Button className="next">
                              <i
                                className="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default QnAAdmin;
