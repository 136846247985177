import { HttpAdminMainApi, GetchpwdParam } from "@app/interface/admin-main-api";
import React, { useEffect, useState, useRef } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

interface propsType {
  inputId: string;
  password: string;
}

export default function ChangePassword(props: propsType) {
  const adminApi = new HttpAdminMainApi();
  const [historyInfo, setHistoryInfo] = useState<any>([]);

  //pwd 변경
  const closeRef = useRef(null);
  const ori_pwd = useRef(null);
  const ch_pwd1 = useRef(null);
  const ch_pwd2 = useRef(null);
  const [oriPwd, setOriPwd] = useState("");
  const [chPwd1, setChPwd1] = useState("");
  const [chPwd2, setChPwd2] = useState("");
  const [oriPwdResult, setOriPwdResult] = useState("");
  const [chPwd1Result, setChPwd1Result] = useState("");
  const [chPwd2Result, setChPwd2Result] = useState("");
  const [chPwdColor1, setChPwdColor1] = useState("red");
  const [chPwdColor2, setChPwdColor2] = useState("red");
  const [valresult, setValresult] = useState(false);
  const [valresult2, setValresult2] = useState(false);
  const chbutton = useRef(null);
  function valpwd1() {
    setChPwd2("");
    setChPwd2Result("");
    setValresult2(false);
    const regex =
      /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*(),.?":{}|<>])[0-9a-zA-Z!@#$%^&*(),.?":{}|<>]{8,20}$/;

    if (chPwd1.length === 0) {
      setChPwd1Result("변경할 비밀번호를 넣어주세요.");
      setChPwdColor1("red");
      setValresult(false);
    } else if (chPwd1.length < 8) {
      setChPwd1Result("8자리 이상 입력이 필요합니다.");
      setChPwdColor1("red");
      setValresult(false);
    } else if (!regex.test(chPwd1)) {
      setChPwd1Result("(영문+숫자+특수문자) 조건에 만족하지 않습니다.");
      setChPwdColor1("red");
      setValresult(false);
    } else {
      setChPwd1Result("사용할 수 있습니다.");
      setChPwdColor1("#2C8B25");
      setValresult(true);
    }
    if (valresult && valresult2) {
      chbutton.current.classList.add("buttonLoginDisabled");
    } else {
      chbutton.current.classList.remove("buttonLoginDisabled");
    }
  }
  function valpwd2() {
    if (chPwd1 !== chPwd2) {
      setChPwd2Result("비밀번호가 일치하지 않습니다.");
      setChPwdColor2("red");
      setValresult2(false);
    } else {
      setChPwd2Result("비밀번호가 일치합니다.");
      setChPwdColor2("#2C8B25");
      setValresult2(true);
    }
    if (valresult && valresult2) {
      chbutton.current.classList.add("buttonLoginDisabled");
    } else {
      chbutton.current.classList.remove("buttonLoginDisabled");
    }
  }
  function orivalpwd() {}

  const get_update_pwd = async () => {
    const param: GetchpwdParam = {
      userId: props.inputId,
      password: chPwd1,
    };
    const res = await adminApi.get_update_pwd(param);
    if ("" + res.code === "200") {
      alert("변경이 완료되었습니다.");
      closeRef.current.click();
    }
  };

  useEffect(() => {
    setOriPwd(props.password);
    setChPwd1("");
    setChPwd2("");
    setChPwd1Result("");
    setChPwd2Result("");
  }, [props.inputId, props.password]); //[] 호출시마다 조회

  return (
    <div>
      <div className="modal fade" id="modal-default">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{props.inputId} 비밀번호 변경</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="timeline-body">. 현재 비밀번호</div>
              <InputGroup>
                <Form.Control
                  className="idform userID"
                  style={{ backgroundColor: "#ffffff", marginBottom: "0.5rem" }}
                  id="oriPwd"
                  name="oriPwd"
                  type="password"
                  ref={ori_pwd}
                  onKeyUp={orivalpwd}
                  placeholder="현재 패스워드를 입력해주세요"
                  onChange={(event) => setOriPwd(event.target.value)}
                  value={oriPwd}
                />
              </InputGroup>
              <div className="timeline-body">
                <span style={{ marginRight: "0.5rem" }}>. 변경 비밀번호</span>
                {chPwd1Result && (
                  <p style={{ color: chPwdColor1, display: "inline" }}>
                    {chPwd1Result}
                  </p>
                )}
              </div>

              <InputGroup>
                <Form.Control
                  className="idform userID"
                  style={{ backgroundColor: "#ffffff", marginBottom: "0.5rem" }}
                  id="chPwd1"
                  name="chPwd1"
                  type="password"
                  ref={ch_pwd1}
                  onKeyUp={valpwd1}
                  placeholder="변경할 패스워드를 입력해주세요"
                  onChange={(event) => setChPwd1(event.target.value)}
                  value={chPwd1}
                />
              </InputGroup>
              <div className="timeline-body">
                <span style={{ marginRight: "0.5rem" }}>. 비밀번호 확인</span>
                {chPwd2Result && (
                  <p style={{ color: chPwdColor2, display: "inline" }}>
                    {chPwd2Result}
                  </p>
                )}
              </div>
              <InputGroup>
                <Form.Control
                  className="idform userID"
                  style={{ backgroundColor: "#ffffff", marginBottom: "0.5rem" }}
                  id="chPwd2"
                  name="chPwd2"
                  type="password"
                  ref={ch_pwd2}
                  onKeyUp={valpwd2}
                  placeholder="확인용 패스워드를 입력해주세요"
                  onChange={(event) => setChPwd2(event.target.value)}
                  value={chPwd2}
                />
              </InputGroup>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="buttonPwdCh"
                  ref={chbutton}
                  disabled={!valresult || !valresult2}
                  onClick={() => {
                    get_update_pwd();
                  }}
                >
                  비밀번호 변경
                </button>
                <div className="timeline-item">
                  <h5 className="timeline-header">사용 안내</h5>
                  <div className="timeline-body">
                    . 8~20자 사이로 영문,숫자,특수문자 사용이 필수 입니다.
                  </div>
                  <div className="timeline-body">
                    . 타인이 쉽게 알아낼 수 있는 비밀번호는 위험합니다.
                  </div>
                  <div className="timeline-body">
                    . 비밀번호를 변경하지 않을시 서비스를 이용하실 수 없습니다.
                  </div>
                  <div className="timeline-footer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
