import React from "react";
import {
  Button,
  Container,
  Form,
  Row,
  Col,
  InputGroup,
  Collapse,
  Table,
} from "react-bootstrap";
interface propsType {
  answer: string;
  date: string;
}

const QnAAnswerBox = (props: propsType) => {
  return (
    <tr>
      <td colSpan={4}>
        <div
          className="answer-box"
          style={{ whiteSpace: "pre-wrap", overflow: "auto" }}
        >
          <span className="index-icon index-a">A</span>
          <span>안녕하세요. AI 카피라이터 루이스입니다.</span>
          <br />
          <br />
          <span>{props.answer}</span>

          <span className="date">{props.date}</span>
        </div>
      </td>
    </tr>
  );
};

export default QnAAnswerBox;
