import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Main from "@modules/main/Main";
import Login from "@modules/login/Login";
import { useWindowSize } from "@app/hooks/useWindowSize";
import { calculateWindowSize } from "@app/utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { setWindowSize } from "@app/store/reducers/ui";

import PublicRoute from "./routes/PublicRoute";

import { userState } from "./interface/MainInterface";
import * as AuthMain from "./auth/AuthMain";
import Mypage01 from "./pages/mypage/Mypage01";
import Mypage02 from "./pages/mypage/Mypage02";
import Copy03 from "./pages/copy03/Copy03";
import Notfound from "./modules/main/notfound/Notfound";
import Mypage03 from "./pages/mypage/Mypage03";
import Polocy from "./pages/policy/Policy";
import Agree from "./pages/policy/Agree";
import QnAWrite from "./pages/qna/QnAWrite";
import QnAList from "./pages/qna/QnAList";
import QnADetail from "./pages/qna/QnADetail";
import QnAAdmin from "./pages/qna/QnAAdmin";
import QnAAdminDetail from "./pages/qna/QnAAdminDetail";
import Landing from "@modules/login/Landing";
import AdminIdLogin from "./modules/login/AdminIdLogin";
import { appConfig } from "./config/Config";

let userParam: userState = {
  id: "",
  userName: "",
  userInfo1: "",
  userInfo2: "",
  userInfo3: "",
  userInfo4: "",
  userType: "",
};

const App = () => {
  const [userState, setUserState] = useState<userState>();
  async function setUserInfo() {
    const result = AuthMain.getSession();
    if (result !== null) {
      userParam.id = result.id;
      userParam.userName = result.userName;
      userParam.userInfo1 = result.userInfo1;
      userParam.userInfo2 = result.userInfo2;
      userParam.userInfo3 = result.userInfo3;
      userParam.userInfo4 = result.userInfo4;
      userParam.userType = result.userType;
    }
    setUserState(userParam);
  }

  const windowSize = useWindowSize();
  const screenSize = useSelector((state: any) => state.ui.screenSize);
  const dispatch = useDispatch();

  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
  }, [windowSize]);

  useEffect(() => {
    // 모든 페이지를 리다이렉팅 시켜야 함
    // 기존 데이터를 가지고 있다면 로그아웃 시켜버림
    // console.log("window.location.pathname : " + window.location.pathname);
    if (
      appConfig.sessionName === "lewisuserss" &&
      window.location.pathname !== "/login"
    ) {
      AuthMain.signOut();
      window.location.href = "/login";
    } else {
      setUserInfo();
    }
  }, []);

  return (
    <>
      {userState !== undefined ? (
        <BrowserRouter>
          <Routes>
            {userState.id !== "" ? (
              <>
                <Route path="/login" element={<PublicRoute />}>
                  <Route path="/login" element={<Login />} />
                </Route>
                <Route path="/" element={<PublicRoute />}>
                  <Route path="/" element={<Main userState={userState} />}>
                    <Route
                      path="/copy03"
                      element={<Copy03 userState={userState} />}
                    />
                    <Route
                      path="/mypage01"
                      element={<Mypage01 userState={userState} />}
                    />
                    <Route
                      path="/mypage02"
                      element={<Mypage02 userState={userState} />}
                    />
                    <Route
                      path="/mypage03"
                      element={<Mypage03 userState={userState} />}
                    />
                    <Route
                      path="/policy"
                      element={<Polocy userState={userState} />}
                    />
                    <Route
                      path="/agree"
                      element={<Agree userState={userState} />}
                    />
                    <Route
                      path="/"
                      element={<Copy03 userState={userState} />}
                    />
                    <Route
                      path="/qna/write"
                      element={<QnAWrite userState={userState} />}
                    />
                    <Route
                      path="/qna/list/:paging"
                      element={<QnAList userState={userState} />}
                    />
                    <Route
                      path="/qnaAdmin"
                      element={<QnAAdmin userState={userState} />}
                    />
                    <Route
                      path="/qna/detail"
                      // 여기서 넘기는 법 확인
                      element={<QnADetail />}
                    />
                    <Route
                      path="/qna/detailAdmin"
                      element={<QnAAdminDetail />}
                    />
                    {/* <Route path="/landing/:uuid" element={<Landing />} /> */}
                    <Route
                      path="*"
                      element={<Notfound userState={userState} />}
                    />
                  </Route>
                </Route>
              </>
            ) : (
              <Route path="/" element={<PublicRoute />}>
                <Route path="/landing/:uuid" element={<Landing />} />
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/adminidLogin" element={<AdminIdLogin />} />
              </Route>
            )}
          </Routes>
          <ToastContainer
            autoClose={3000}
            draggable={false}
            position="top-right"
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnHover
          />
        </BrowserRouter>
      ) : (
        <></>
      )}
    </>
  );
};

export default App;
