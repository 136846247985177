import { HttpAdminMainApi, GetHistoryInfoParam } from "@app/interface/admin-main-api";
import React, { useEffect, useState, useRef } from "react";
import { userState } from "@app/interface/MainInterface";
import BookMarkClick from "../reaction/BookMarkClick";
import LikeClick from "../reaction/LikeClick";
import UnLikeClick from "../reaction/UnLikeClick";
// import Tooltip from "react-bootstrap/Tooltip";
import Tooltip from "@mui/material/Tooltip";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

interface propsType {
  userState: userState;
  pk: string;
  range: string;
}

//ToDo 즐겨찾기를 위해 해시태그를 모으고, 해시태그로 검색하자
export default function HistoryBox(props: propsType) {
  const adminApi = new HttpAdminMainApi();
  const [historyInfo, setHistoryInfo] = useState<any>([]);
  // 히스토리 상세 숨기기
  const [result01Show, setResult01ShowShow] = useState(false);
  const [copyContent, setCopyContent] = useState("문장 복사");
  const [copyTitle, setCopyTitle] = useState("제목 복사");

  const swiperRef = useRef<any>(null);
  const get_history_info = async () => {
    const param: GetHistoryInfoParam = {
      pk: props.pk,
      range: props.range,
    };
    const res = await adminApi.get_history_info(param);
    if ("" + res.code === "200") {
      setHistoryInfo(res.response.data_info);
    } else {
      console.error("get_product_history_info ERROR : ", res.response.error_msg);
    }
  };
  const handleCopyClipBoard = async (text: string) => {
    try {
      setCopyContent("복사 완료");
      setCopyTitle("복사 완료");
      setTimeout(() => {
        setCopyContent("문장 복사");
        setCopyTitle("제목 복사");
      }, 1000);
      await navigator.clipboard.writeText(text);
    } catch (error) {}
  };

  useEffect(() => {
    get_history_info();
  }, []); //[] 호출시마다 조회

  return (
    <div>
      {historyInfo.map((item: any, index: number) => (
        <div className="timeline-react" key={item[0].range}>
          <div id="swiper" className="swiper mySwiper result-list timeline-sl">
            <div>
              {/* timeline item */}
              <Swiper
                ref={swiperRef}
                id="swiper"
                tag="section"
                loop={false}
                navigation
                pagination={{ type: "fraction" }}
                slidesPerView={1}
                onSlideChange={(swiper) => {}}
              >
                {item[2].map((contentList: any, index: number) => (
                  <SwiperSlide key={contentList.content_no}>
                    <div className="swiper-slide">
                      <div className="slide-cont">
                        <span className="time">좋아요 {item[0].like} 개</span>
                        <dl>
                          <dt>
                            <Tooltip
                              title={copyTitle}
                              placement="top"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "common.black",
                                    "& .MuiTooltip-arrow": {
                                      color: "common.black",
                                    },
                                    fontSize: "0.8rem",
                                  },
                                },
                              }}
                              arrow
                            >
                              <span className="timeline-header" onClick={() => handleCopyClipBoard(item[1][0].title)}>
                                {item[1][0].title}
                              </span>
                            </Tooltip>
                          </dt>
                          <div className="timeline-body">
                            <dd>
                              <span style={{ whiteSpace: "pre-wrap" }}>{contentList.content}</span>
                            </dd>
                          </div>
                        </dl>
                        <div className="kw-wrap">
                          {item[0].input_list.product.map((product: any, index: number) => (
                            <Tooltip
                              key={index}
                              title="제품/브랜드에서 입력된 값입니다."
                              placement="bottom"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "common.black",
                                    "& .MuiTooltip-arrow": {
                                      color: "common.black",
                                    },
                                    fontSize: "0.8rem",
                                  },
                                },
                              }}
                              arrow
                            >
                              <span className="kw-tag product">#{product}</span>
                            </Tooltip>
                          ))}
                          {item[0].input_list.keyword.map((keyword: any, index: number) => (
                            <Tooltip
                              key={index}
                              title="테마/키워드/시즌 에서 입력된 값입니다."
                              placement="bottom"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "common.black",
                                    "& .MuiTooltip-arrow": {
                                      color: "common.black",
                                    },
                                    fontSize: "0.8rem",
                                  },
                                },
                              }}
                              arrow
                            >
                              <span className="kw-tag keyword">#{keyword}</span>
                            </Tooltip>
                          ))}
                        </div>
                        <div className="slide-icon-wrap">
                          <Tooltip
                            title={copyContent}
                            placement="top"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: "common.black",
                                  "& .MuiTooltip-arrow": {
                                    color: "common.black",
                                  },
                                  fontSize: "0.8rem",
                                },
                              },
                            }}
                            arrow
                          >
                            <i
                              className="fas fa-icon fa-clone"
                              onClick={() => handleCopyClipBoard(item[2][0].content)}
                            />
                          </Tooltip>
                          <LikeClick
                            pk={item[0].pk}
                            range={item[0].range}
                            category={"main"}
                            subRange={item[0].range}
                            userState={props.userState}
                            inputList={item[0].input_list}
                          ></LikeClick>
                          <UnLikeClick
                            pk={item[0].pk}
                            range={item[0].range}
                            category={"main"}
                            subRange={item[0].range}
                            userState={props.userState}
                            inputList={item[0].input_list}
                          ></UnLikeClick>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
