import { useInterval } from "@app/hooks/UseInterval";
interface propsType {
  isLoading: boolean;
  loadingTexts: any;
  interval: number;
}

const LoadingBar = (props: propsType) => {
  let typingIdx = 0;
  let liIndex = 0;
  let liLength = props.loadingTexts.length;
  let typingTxt = "";
  useInterval(
    () => {
      if (props.isLoading) {
        // 처음 문자열을 읽어오기
        let nowText = props.loadingTexts[liIndex];
        typingTxt = nowText.split("");
        if (typingIdx < typingTxt.length) {
          // 타이핑될 텍스트 길이만큼 반복
          try {
            document.getElementById("loadingText").append(typingTxt[typingIdx]); // 한글자씩 이어준다.
            typingIdx++;
          } catch (error) {}
        } else {
          //다음문장으로.. 마지막문장이면 다시 첫번째 문장으로
          if (liIndex >= liLength - 1) {
            liIndex = 0;
          } else {
            liIndex++;
          }
          typingIdx = 0;
          try {
            document.getElementById("loadingText").innerHTML = "";
          } catch (error) {}
        }
      }
    },
    props.isLoading ? props.interval : null
  );

  return (
    <>
      {props.isLoading ? (
        <div className="modal-loading">
          <div className="load-cont">
            <div className="spinner-wrap">
              <div className="spinner">
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
            <dl>
              <dt>
                <p className="typing" id="loadingText"></p>
              </dt>
            </dl>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default LoadingBar;