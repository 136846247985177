import React, { useEffect } from "react";
import { userState } from "@app/interface/MainInterface";

interface propsType {
  userState: userState;
}
export default function Notfound(props: propsType) {
  useEffect(() => {
    if (!props.userState.id) {
      window.location.href = "/login";
    }
  }, []); //[] 호출시마다 조회
  return (
    <div className="wrapper lewis">
      <div className="error-wrap">
        <ul>
          <li>
            <dl>
              <dt>잠깐!</dt>
              <dd>해당 페이지가 존재하지 않습니다.</dd>
            </dl>
          </li>
          <li>
            <img src="dist/images/error-img.png" />
          </li>
        </ul>
      </div>
    </div>
  );
}
