import React, { useEffect, useState, useRef } from "react";
import BookMarkClick from "../reaction/BookMarkClick";
import LikeClick from "../reaction/LikeClick";
import UnLikeClick from "../reaction/UnLikeClick";
import {
  HttpAdminMainApi,
  GetHistoryInfoParam,
  GetGtpTotalParam,
  GetGtpContentParam,
} from "@app/interface/admin-main-api";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from "swiper";
import "swiper/swiper-bundle.css";
import PulseLoader from "react-spinners/PulseLoader";
import { Row } from "react-bootstrap";
import { userState } from "@app/interface/MainInterface";
import Skeleton from "react-loading-skeleton";
import Tooltip from "@mui/material/Tooltip";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);
interface propsType {
  userState: userState;
  pk: string;
  range: string;
  dataText: string;
  joinText: string;
}

export default function TotalListBox(props: propsType) {
  let [isLoading, setLoading] = useState(false);
  const adminApi = new HttpAdminMainApi();
  const swiperRef = useRef<any>(null);
  const [historyInfo, setHistoryInfo] = useState<any>([]);
  const [slideTo, setSlideTo] = useState(0);
  const [toolTipShow, setToolTipShow] = useState(false);
  const target = useRef(null);
  const [copyContent, setCopyContent] = useState("문장 복사");

  const get_history_info = async () => {
    // console.log("slideTo : ",slideTo)
    if (slideTo % 3 === 0) {
      setToolTipShow(true);
    } else {
      setToolTipShow(false);
    }
    const param: GetHistoryInfoParam = {
      pk: props.pk,
      range: props.range,
    };

    const res = await adminApi.get_history_info(param);
    if ("" + res.code === "200") {
      setHistoryInfo(res.response.data_info);
      setSlideTo(res.response.data_info[0][2].length);
      swiperRef.current?.swiper.slideTo(res.response.data_info[0][2].length);
    } else {
      console.error("get_history_info ERROR : ", res.response.error_msg);
    }
  };
  //Contents 조회
  const [multipleText, setMultipleText] = useState("0");

  const get_gpt_content_info = async (event: string) => {
    const param: GetGtpContentParam = {
      userState: props.userState,
      pk: props.pk,
      range: props.range,
      dataText: props.dataText,
      joinText: props.joinText,
      titleText: event,
      multipleText: multipleText,
    };
    setLoading(true);
    setToolTipShow(false);
    const res = await adminApi.get_gpt_content_info(param);
    if ("" + res.code === "200") {
      if (multipleText === "0") {
        setMultipleText("1");
      } else {
        setMultipleText("0");
      }
      get_history_info();
    } else {
      setLoading(false);
      console.error("get_gpt_content_info ERROR : ", res.response.error_msg);
    }
    setLoading(false);
  };

  const handleCopyClipBoard = async (text: string) => {
    try {
      setCopyContent("복사 완료");
      setTimeout(() => {
        setCopyContent("문장 복사");
      }, 2000);
      await navigator.clipboard.writeText(text);
    } catch (error) {}
  };

  useEffect(() => {
    get_history_info();
  }, []); //[] 호출시마다 조회

  useEffect(() => {
    get_history_info();
  }, [slideTo]); //[] 호출시마다 조회

  return (
    <div className="col-md-6">
      {historyInfo.map((item: any, index: number) => (
        <div className="pad table-responsive" key={item[0].range}>
          <div className="callout callout-info position-relative">
            {isLoading ? (
              <h5>
                <Skeleton width="40%" count={1} />
              </h5>
            ) : (
              <h5>{item[1][0].title}</h5>
            )}
            {isLoading ? (
              <p>
                <Skeleton width="80%" count={2} />
              </p>
            ) : (
              <p>{item[2][0].content}</p>
            )}
            <div className="result-btn-wrap">
              <Tooltip
                title={copyContent}
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "common.black",
                      "& .MuiTooltip-arrow": {
                        color: "common.black",
                      },
                      fontSize: "0.8rem",
                    },
                  },
                }}
                arrow
              >
                <i className="fas fa-icon fa-clone" onClick={() => handleCopyClipBoard(item[2][0].content)} />
              </Tooltip>
              <LikeClick
                pk={item[0].pk}
                range={item[0].range}
                category={"main"}
                subRange={item[0].range}
                userState={props.userState}
                inputList={item[0].input_list}
              ></LikeClick>
              <UnLikeClick
                pk={item[0].pk}
                range={item[0].range}
                category={"main"}
                subRange={item[0].range}
                userState={props.userState}
                inputList={item[0].input_list}
              ></UnLikeClick>
              {/* <BookMarkClick
                pk={item[0].pk}
                range={item[0].range}
                category={"main"}
                subRange={item[0].range}
                userState={props.userState}
                inputList={item[0].input_list}
              ></BookMarkClick> */}
              {isLoading ? (
                <>
                  <Row>
                    <i className="next-btn-click">
                      <PulseLoader
                        color="#FF6C58"
                        cssOverride={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                        size={9}
                      />
                    </i>
                  </Row>
                </>
              ) : (
                <Tooltip
                  title={"키워드를 바꾸면 다양한 문장이 만들어집니다."}
                  placement="left"
                  open={toolTipShow}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "common.black",
                        "& .MuiTooltip-arrow": {
                          color: "common.black",
                        },
                        fontSize: "0.8rem",
                      },
                    },
                  }}
                  arrow
                >
                  <i
                    ref={target}
                    className="next-btn"
                    onClick={() => {
                      get_gpt_content_info(item[1][0].title);
                    }}
                  >
                    본문추가생성
                  </i>
                </Tooltip>
              )}
            </div>
            <div className="swiper mySwiper result-list">
              <div>
                <Swiper
                  ref={swiperRef}
                  id="swiper"
                  tag="section"
                  loop={false}
                  navigation
                  pagination={{ type: "fraction" }}
                  slidesPerView={1}
                  onSlideChange={(swiper) => {}}
                >
                  {item[2].map((contentList: any, index: number) => (
                    <SwiperSlide key={contentList.content_no}>
                      <div className="swiper-slide">
                        <div className="slide-cont">
                          <dl>
                            <dd>{contentList.content}</dd>
                          </dl>
                          <div className="slide-icon-wrap">
                            <Tooltip
                              title={copyContent}
                              placement="top"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "common.black",
                                    "& .MuiTooltip-arrow": {
                                      color: "common.black",
                                    },
                                    fontSize: "0.8rem",
                                  },
                                },
                              }}
                              arrow
                            >
                              <i
                                className="fas fa-icon fa-clone"
                                onClick={() => handleCopyClipBoard(contentList.content)}
                              />
                            </Tooltip>
                            <LikeClick
                              pk={contentList.pk}
                              range={contentList.content_no}
                              category={"content"}
                              subRange={contentList.range}
                              userState={props.userState}
                              inputList={item[0].input_list}
                            ></LikeClick>
                            <UnLikeClick
                              pk={contentList.pk}
                              range={contentList.content_no}
                              category={"content"}
                              subRange={contentList.range}
                              userState={props.userState}
                              inputList={item[0].input_list}
                            ></UnLikeClick>
                            {/* <BookMarkClick
                              pk={contentList.pk}
                              range={contentList.content_no}
                              category={"content"}
                              subRange={contentList.range}
                              userState={props.userState}
                              inputList={item[0].input_list}
                            ></BookMarkClick> */}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
