import React from "react";
import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const Footer = () => {
  const [t] = useTranslation();

  return (
    <footer className="main-footer roboto">
      <strong>&copy;Hyundai Department Store. All rights reserved.</strong>
      <ul>
        <li>
          <a href="#">서비스이용약관</a>
        </li>
        <li>
          <a href="#">개인정보처리방침</a>
          {/* <a href="/policy">개인정보처리방침</a> */}
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
