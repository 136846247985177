import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toggleControlSidebar, toggleSidebarMenu } from "@app/store/reducers/ui";
import MessagesDropdown from "@app/modules/main/header/messages-dropdown/MessagesDropdown";
import NotificationsDropdown from "@app/modules/main/header/notifications-dropdown/NotificationsDropdown";

const Header = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navbarVariant = useSelector((state: any) => state.ui.navbarVariant);
  const headerBorder = useSelector((state: any) => state.ui.headerBorder);

  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  const handleToggleControlSidebar = () => {
    dispatch(toggleControlSidebar());
  };

  const getContainerClasses = useCallback(() => {
    let classes = `main-header navbar navbar-expand ${navbarVariant}`;
    if (headerBorder) {
      classes = `${classes} border-bottom-0`;
    }
    return classes;
  }, [navbarVariant, headerBorder]);

  const signOut = () => {
    if (!window.confirm("로그아웃 하시겠습니까?")) return;
    window.localStorage.removeItem("lewisuserss");
    window.location.href = "/login";
  };

  return (
    <nav className={getContainerClasses()}>
      <ul className="navbar-nav">
        <li className="nav-item nav-btn">
          <button onClick={handleToggleMenuSidebar} type="button" className="nav-link">
            <i className="fas fa-bars" />
          </button>
        </li>

        {/* <li className="nav-item d-none d-sm-inline-block">
          <Link to="/" className="nav-link">
            {t<string>('header.label.contact')}
          </Link>
        </li> */}
      </ul>
      <ul className="navbar-nav ml-auto">
        {/* <MessagesDropdown /> */}
        {/* <NotificationsDropdown /> */}
        <li className="nav-item">
          <a className="nav-link" data-widget="logout" href="#" role="button" onClick={signOut}>
            <i className="fa fa-power-off" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
