import { ApiBase } from "./api-base";
import { userState, inputList } from "@app/interface/MainInterface";
import { appConfig } from "@app/config/Config";
import axios from "axios";

//로그인 임시

export interface GetLoginParam {
  userId: string;
  password: string;
}

export interface CheckLoginParam {
  userId: string;
}
export interface GetchpwdParam {
  userId: string;
  password: string;
}

export interface GetDashboardListParam {
  userState: userState;
}

// Lewis Start
export interface GetHistoryListParam {
  userState: userState;
  dataText: string;
  fromDateText: string;
  toDateText: string;
}

export interface GetHistoryInfoParam {
  pk: string;
  range: string;
}

export interface GetGtpTotalParam {
  userState: userState;
  dataText: string;
  joinText: string;
  multipleText: string;
  make_num: string;
}

export interface GetGtpTitleParam {
  userId: string;
  dataText: string;
  joinText: string;
  multipleText: string;
}

export interface GetGtpContentParam {
  userState: userState;
  pk: string;
  range: string;
  dataText: string;
  joinText: string;
  titleText: string;
  multipleText: string;
}

export interface GetBookMarListParam {
  userId: string;
}

export interface GetMyBookMarkListParam {
  userId: string;
  dataText: string;
}

export interface PutBookMarkParam {
  pk: string;
  range: string;
  category: string;
  subRange: string;
  userState: userState;
  inputList: inputList;
}

export interface GetBookMarkInfoParam {
  userState: userState;
  range: string;
}

export interface GetLikeInfoParam {
  userState: userState;
  range: string;
}

export interface PutLikeInfoParam {
  pk: string;
  range: string;
  category: string;
  subRange: string;
  userState: userState;
  inputList: inputList;
}

export interface GetMyLikeListParam {
  userId: string;
  dataText: string;
  fromDateText: string;
  toDateText: string;
}

export interface GetUnLikeInfoParam {
  userState: userState;
  range: string;
}

export interface PutUnLikeInfoParam {
  pk: string;
  range: string;
  category: string;
  subRange: string;
  userState: userState;
  inputList: inputList;
}

export interface GetMyHistoryListParam {
  userId: string;
  dataText: string;
  fromDateText: string;
  toDateText: string;
}

// qna
export interface GetQnACntParam {
  userState: userState;
}

export interface GetQnAListParam {
  userState: userState;
  page: number;
}

export interface PostQuestion {
  qseq: string;
  title: string;
  content: string;
  userState: userState;
}

interface RePostQuestion {
  seq: string;
  reqseq: string;
  title: string;
  content: string;
  userState: userState;
}

export interface PostAnswer {
  aseq: string;
  content: string;
  oriseq: string;
  userState: userState;
}

// Lewis END

export class HttpAdminMainApi extends ApiBase {
  onApiName(): string {
    return "AdminMainApi";
  }

  onUrlPath(): string {
    // 로컬 -> inte_ai -> 현재 사용중이지 않기 때문
    // 실제 -> lewis01 -> 로그인 할때 사용
    if (window.location.hostname === "localhost") {
      return appConfig.testApiUrl;
    } else {
      return appConfig.mainApiUrl;
    }
  }

  // 로그인
  public async get_user_info(params: GetLoginParam) {
    const requestBody = {
      command: "get_user_info",
      userId: params.userId,
      password: params.password,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  // 로그인 DDB 접속해서 UUID 가져오기
  public async get_user_info_uuid(params: string) {
    const requestBody = {
      command: "get_user_info_uuid",
      uuid: params,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  // RDB랑 통신 확인하는 코드
  public async check_user_rds(keyId: string) {
    const requestBody = {
      command: "check_user_rds",
      userId: keyId,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async check_company_url(companyName: string) {
    const requestBody = {
      command: "check_company_url",
      company: companyName,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_update_pwd(params: GetchpwdParam) {
    const requestBody = {
      command: "get_update_pwd",
      userId: params.userId,
      password: params.password,
    };

    const response = this.requestPost(requestBody);
    return response;
  }
  //대시보드
  public async get_dashboard_list(params: GetDashboardListParam) {
    const requestBody = {
      command: "get_dashboard_list",
      userState: params.userState,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // history 검색
  //--------------------------------------------------------------

  public async get_history_list(params: GetHistoryListParam) {
    const requestBody = {
      command: "get_history_list",
      userState: params.userState,
      dataText: params.dataText,
      fromDateText: params.fromDateText,
      toDateText: params.toDateText,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_history_info(params: GetHistoryInfoParam) {
    const requestBody = {
      command: "get_history_info",
      pk: params.pk,
      range: params.range,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // Gpt 검색
  //--------------------------------------------------------------
  public async get_gpt_total_info(params: GetGtpTotalParam) {
    const requestBody = {
      command: "get_gpt_total_info",
      userState: params.userState,
      dataText: params.dataText,
      joinText: params.joinText,
      multipleText: params.multipleText,
      make_num: params.make_num,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_gpt_title_info(params: GetGtpTitleParam) {
    const requestBody = {
      command: "get_gpt_title_info",
      userId: params.userId,
      dataText: params.dataText,
      joinText: params.joinText,
      multipleText: params.multipleText,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_gpt_content_info(params: GetGtpContentParam) {
    const requestBody = {
      command: "get_gpt_content_info",
      userState: params.userState,
      pk: params.pk,
      range: params.range,
      dataText: params.dataText,
      joinText: params.joinText,
      titleText: params.titleText,
      multipleText: params.multipleText,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // bookmark 관리
  //--------------------------------------------------------------
  public async get_bookmark_list(params: GetBookMarListParam) {
    const requestBody = {
      command: "get_bookmark_list",
      userId: params.userId,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_bookmark_list(params: GetMyBookMarkListParam) {
    const requestBody = {
      command: "get_my_bookmark_list",
      userId: params.userId,
      dataText: params.dataText,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_bookmark_info(params: GetBookMarkInfoParam) {
    const requestBody = {
      command: "get_bookmark_info",
      userState: params.userState,
      range: params.range,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_bookmark_info(params: PutBookMarkParam) {
    const requestBody = {
      command: "put_bookmark_info",
      pk: params.pk,
      range: params.range,
      category: params.category,
      subRange: params.subRange,
      userState: params.userState,
      inputList: params.inputList,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // like 관리
  //--------------------------------------------------------------
  public async get_my_like_list(params: GetMyLikeListParam) {
    const requestBody = {
      command: "get_my_like_list",
      userId: params.userId,
      dataText: params.dataText,
      fromDateText: params.fromDateText,
      toDateText: params.toDateText,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_like_info(params: GetLikeInfoParam) {
    const requestBody = {
      command: "get_like_info",
      userState: params.userState,
      range: params.range,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_like(params: PutLikeInfoParam) {
    const requestBody = {
      command: "put_like",
      pk: params.pk,
      range: params.range,
      category: params.category,
      subRange: params.subRange,
      userState: params.userState,
      inputList: params.inputList,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // unlike 관리
  //--------------------------------------------------------------
  public async get_unlike_info(params: GetUnLikeInfoParam) {
    const requestBody = {
      command: "get_unlike_info",
      userState: params.userState,
      range: params.range,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_unlike(params: PutUnLikeInfoParam) {
    const requestBody = {
      command: "put_unlike",
      pk: params.pk,
      range: params.range,
      category: params.category,
      subRange: params.subRange,
      userState: params.userState,
      inputList: params.inputList,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // mypage 관리
  //--------------------------------------------------------------
  public async get_my_history_list(params: GetMyHistoryListParam) {
    const requestBody = {
      command: "get_my_history_list",
      userId: params.userId,
      dataText: params.dataText,
      fromDateText: params.fromDateText,
      toDateText: params.toDateText,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // qna 관리
  //--------------------------------------------------------------
  public async get_my_qna_cnt(params: GetQnACntParam) {
    const requestBody = {
      command: "get_my_qna_cnt",
      userState: params.userState,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_qna_list(params: GetQnAListParam) {
    const requestBody = {
      command: "get_my_qna_list",
      userState: params.userState,
      paging: params.page,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async post_question(params: PostQuestion) {
    const requestBody = {
      command: "post_question",
      title: params.title,
      qseq: params.qseq,
      content: params.content,
      userState: params.userState,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async repost_question(params: RePostQuestion) {
    const requestBody = {
      command: "repost_question",
      title: params.title,
      reqseq: params.reqseq,
      oriseq: params.seq,
      content: params.content,
      userState: params.userState,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_quetion_detail(qseq: string) {
    const requestBody = {
      command: "get_my_question_detail",
      seq: qseq,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_answer_detail(qseq: string) {
    const requestBody = {
      command: "get_my_answer_detail",
      seq: qseq,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_admin_qna_list(userType: string) {
    const requestBody = {
      command: "get_admin_qna_list",
      userType,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async post_answer(params: PostAnswer) {
    const requestBody = {
      command: "post_answer",
      aseq: params.aseq,
      content: params.content,
      oriseq: params.oriseq,
      userState: params.userState,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // 공통 모듈
  //--------------------------------------------------------------
  public async get_presigned_url(key: string) {
    const requestBody = {
      command: "get_presigned_url",
      key: key,
    };
    const response = this.requestPost(requestBody);
    return response;
  }
}
