import { GetUnLikeInfoParam, HttpAdminMainApi, PutUnLikeInfoParam } from '@app/interface/admin-main-api';
import React, { useState, useEffect } from 'react'
import { userState, inputList } from "@app/interface/MainInterface";

interface propsType {
    pk: string;
    range: string;
    category: string;
    subRange: string;
    userState: userState;
    inputList: inputList;
}

export default function UnLikeClick(props: propsType) {
  const adminApi = new HttpAdminMainApi();
  const [classData, setclassData] = useState('fas fa-icon fa-thumbs-down');
  const setClickUnLike = async () => {
      const param: PutUnLikeInfoParam = {
          pk: props.pk,
          range: props.range,
          category: props.category,
          subRange: props.subRange,
          userState: props.userState,
          inputList: props.inputList
      };
      const res = await adminApi.put_unlike(param);
      if ("" + res.code === "200") {
          res.response.result === "Y"
              ? setclassData("fas fa-icon fa-thumbs-down active")
              : setclassData("fas fa-icon fa-thumbs-down")
      } else {
          console.error("setClickUnLike ERROR : ", res.response.error_msg);
      }
  };

  const get_unlike_info = async () => {
    const param: GetUnLikeInfoParam = {
        userState: props.userState,
        range: props.range
    };
    const res = await adminApi.get_unlike_info(param);
    if ("" + res.code === "200") {
        res.response.result === "Y"
            ? setclassData("fas fa-icon fa-thumbs-down active")
            : setclassData("fas fa-icon fa-thumbs-down")
    } else {
        console.error("get_unlike_info ERROR : ", res.response.error_msg);
    }
};

useEffect(() => {
    get_unlike_info();
}, []) //[] 변경시마다  작동

  return (
      <i key={props.range} className={classData} onClick={setClickUnLike} />
  )
     

}
 