import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
// import Table from "react-bootstrap/Table";
import { userState } from "@app/interface/MainInterface";

import { HttpAdminMainApi } from "@app/interface/admin-main-api";

interface propsType {
  userState: userState;
}

interface PostQuestion {
  qseq: string;
  title: string;
  content: string;
  userState: userState;
}

// 1. 생성 버튼을 생성 -> ok
// 버튼 클릭했을때 동작하는 페이지 구현
const QnAWrite = (props: propsType) => {
  let { state } = useLocation();
  // 사용자 정보
  const [userState, setUserState] = useState(state.userState);

  // 로직 붙여야함

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const [qLength, setQLength] = useState(state.qLength);

  const adminApi = new HttpAdminMainApi();
  // 로그인 정보 없으면 로그인 페이지로 이동
  useEffect(() => {
    if (!state.userState.id) {
      window.location.href = "/login";
    }
  }, []); //[] 호출시마다 조회

  const handleTitleChange = (e: any) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (e: any) => {
    setContent(e.target.value);
  };

  const navigate = useNavigate();

  const postQuestion = async () => {
    // title
    // content
    // author
    let today = new Date();
    // console.log("today : " + today.getDay());
    const qseq = `${today.getDay()}${
      userState.id
    }${today.getMilliseconds()}${today.getMinutes()}${qLength}`;

    const userinfo: string = userState.userInfo1;
    const postQuestionData: PostQuestion = {
      qseq,
      title,
      content,
      userState,
    };

    const res = await adminApi.post_question(postQuestionData);
    if ("" + res.code === "200") {
      // console.log("res : " + res);
      if (res.msg === "success") {
        // console.log("저장완료");
        alert("문의 생성이 완료되었습니다!");
      } else {
        // console.log("저장실패");
        alert("문의 생성이 실패하였습니다! 다시 시도해주세요!");
      }
    } else {
      // console.log("status code 실패");
      // console.log(res);
      alert("문의 생성이 실패하였습니다! 다시 시도해주세요!");
    }
    navigate("/qna/list/1");
  };

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 pd-0 mb-4">
              <div className="card card-primary card-outline">
                <div className="card-header">
                  <h3 className="card-title">
                    <i className="fas fa-edit cont-tit qna"></i>
                    무엇을 도와드릴까요?
                  </h3>
                </div>
                <Form className="card-body pad table-responsive bg-box">
                  <div className="qna-cont">
                    <Form.Group
                      className="input-wrap w-title"
                      controlId="title"
                    >
                      <Form.Label className="form-group qna-write">
                        <h4>제목</h4>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="제목을 입력해주세요"
                        className="form-control form-control-lg"
                        onChange={handleTitleChange}
                      />
                    </Form.Group>
                    <Form.Group
                      className="input-wrap w-content"
                      controlId="contents"
                    >
                      <Form.Label className="form-group qna-write">
                        <h4>문의 내용</h4>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        className="form-control form-control-lg"
                        rows={3}
                        style={{ overflow: "visible" }}
                        placeholder="문의하실 내용을 입력해주세요"
                        onChange={handleContentChange}
                      />
                    </Form.Group>
                    <div className="notice">
                      <dl>
                        <dt>
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                          ></i>
                          개인정보보호를 위해 문의 내용에는 휴대폰 번호 등
                          개인정보 입력을 지양하여 주시기 바랍니다.
                        </dt>
                      </dl>
                    </div>
                    <span className="div-line"></span>
                    <button
                      type="button"
                      className="btn btn-block btn-outline-primary"
                      onClick={postQuestion}
                    >
                      문의하기
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default QnAWrite;
