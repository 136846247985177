import React, { useState, useRef, useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import {
  GetLoginParam,
  HttpAdminMainApi,
  GetchpwdParam,
} from "@app/interface/admin-main-api";
import { userState } from "@app/interface/MainInterface";
import jwt_decode from "jwt-decode";
import ChangePassword from "../change-password/ChangePassword";

interface propsType {
  userState: userState;
  callBack: any;
}
let userParam: userState = {
  id: "",
  userName: "",
  userInfo1: "",
  userInfo2: "",
  userInfo3: "",
  userInfo4: "",
  userType: "",
};
//google 로그인 데이터 사용 (이메일, 닉네임)
interface UserObject {
  email: string;
  name: string;
  // 그 외 필요한 프로퍼티들
}

const AdminIdLogin = () => {
  const [showTerms, setShowTerms] = useState(true); //로그인 안보이게
  // function handleCallbackResponse(response: any) {
  //   // console.log("response",response.credential);
  //   var userObject = jwt_decode(response.credential) as UserObject;
  //   console.log("userObject:", userObject);
  //   const sessionData = {
  //     id: userObject.email,
  //     user_name: userObject.name,
  //     user_info1: "CUSTOMER", // MAIN/user_info1 가 핵심 Key임 (고객사, B2C 구분),B2C는 날짜로 키만들지 고민
  //     user_info2: userObject.email,
  //     user_info3: "",
  //     user_info4: "",
  //     user_type: "5",
  //   };
  //   procSesson(sessionData);
  // }

  const getUserKey = (encryptedKey: string) => {
    // http://localhost:3000/login?requestKey=192837465197321144431428385651973&time=20230227
    const before = "1928374651973";
    const after = "1428385651973";
    const result = encryptedKey.replace(before, "").replace(after, "");
    return result;
  };

  // rds 통신해서 람다 호출
  const check_user_rds = async (key_id: string) => {
    const res = await adminApi.check_user_rds(key_id);
    if ("" + res.code == "200") {
      if (res.response.result === "success") procSesson(res.response.session);
    }
  };

  // url 가져오기
  const stateInfo: URLSearchParams = new URLSearchParams(
    window.location.search
  );

  // const decryptKeyTest = (encryptedKey: string) => {
  //   // https://www.ai-lewis.com/login?requestKey=iEn8YWWRX9Gc%2bk4Rp6g43g%3d%3d&time=PhdFa721nR5Mc0CPlEAFrg%3d%3d
  //   // https://localhost/login?requestKey=iEn8YWWRX9Gc%2bk4Rp6g43g%3d%3d&time=PhdFa721nR5Mc0CPlEAFrg%3d%3d
  //   const a = "12345678901234567890Hyundai12345"; // 32바이트인데 단순히 이것만 가지고 되는지 확인
  //   const finalSecretKey = CryptoJS.enc.Utf8.parse(a);
  //   const b = "1234567890hyundai";
  //   const ivKey = CryptoJS.enc.Utf8.parse(b);
  //   // decrypt
  //   const decipher = CryptoJS.AES.decrypt(encryptedKey, finalSecretKey, {
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7,
  //     iv: ivKey,
  //   });
  //   console.log(decipher);
  //   console.log("decipher : " + decipher);
  //   const decryptedKeyData = decipher.toString(CryptoJS.enc.Utf8);
  //   console.log("decrypt and utf-8 : " + decryptedKeyData);
  //   return decryptedKeyData;
  // };

  useEffect(() => {
    // none querystring
    history.replaceState({}, null, location.pathname);

    // requestKey
    if (stateInfo.get("requestKey") && stateInfo.get("time")) {
      const keyInfo: string = stateInfo.get("requestKey");
      const time: string = stateInfo.get("time");
      const customKey = getUserKey(keyInfo);
      let date = new Date();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let days = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let today = `${date.getFullYear()}${month}${days}` + "";
      if (time === today) {
        // 로그인 진행
        check_user_rds(customKey);
      } else {
        //날짜 확인후 주석
        // alert("아이디와 비밀번호로 로그인 진행해주세요");
      }
    } else {
      // 일반적인 로그인 진행
    }
  }, []);

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://accounts.google.com/gsi/client";
  //   script.async = true;
  //   script.onload = () => {
  //     /* global google */
  //     google.accounts.id.initialize({
  //       client_id:
  //         "95032629720-2f90cjlbvtccesmcctjii3m08kb0pebi.apps.googleusercontent.com",
  //       callback: handleCallbackResponse,
  //     });

  //     google.accounts.id.renderButton(
  //       document.getElementById('signInDiv'),
  //       {
  //         type: 'standard',
  //         theme: 'outline',
  //         size: 'large',
  //       }
  //     );
  //   };
  //   procSesson(sessionData);
  // }

  // 처음 한번 실행할때를 위해 넣어줌
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://accounts.google.com/gsi/client";
  //   script.async = true;
  //   script.onload = () => {
  //     /* global google */
  //     google.accounts.id.initialize({
  //       client_id:
  //         "95032629720-2f90cjlbvtccesmcctjii3m08kb0pebi.apps.googleusercontent.com",
  //       callback: handleCallbackResponse,
  //     });

  //     google.accounts.id.renderButton(
  //       document.getElementById('signInDiv'),
  //       {
  //         type: 'standard',
  //         theme: 'outline',
  //         size: 'large',
  //       }
  //     );
  //   };
  //   document.body.appendChild(script);

  //   const naver = (window as any).naver;
  //   const naverLogin: any = new naver.LoginWithNaverId({
  //     clientId : "9rPrQ_M6nyMRsvnutHmv",
  //     callbackUrl : "http://localhost:3000/copy03",
  //     isPopup: false,
  //     loginButton: {color: 'green', type: 3, height: 42},
  //     callbackHandle: true,
  //   })
  //   naverLogin.init();
  // }, []);

  const adminApi = new HttpAdminMainApi();
  const [inputId, setInputId] = useState("");
  const [password, setPassword] = useState("");
  const buttonRef = useRef(null);

  const get_user_info = async () => {
    const param: GetLoginParam = {
      userId: inputId,
      password: password,
    };
    const res = await adminApi.get_user_info(param);
    if ("" + res.code === "200") {
      if (res.response.result === "success") {
        if (res.response.session["FIRST_YN"] === "Y") {
          //최초 로그인 모달창 오픈
          buttonRef.current.click();
        } else {
          procSesson(res.response.session);
        }
      } else {
        alert("아이디 또는 패스워드가 일치하지 않습니다.");
      }
    }
  };

  const procSesson = (sessionData: any) => {
    // // toastRef.current?.toast("로그인 성공", "info", 3000, { vertical: "top", horizontal: "right" });
    const sessionObj: any = {
      id: sessionData.id,
      userName: sessionData.user_name,
      userInfo1: sessionData.user_info1,
      userInfo2: sessionData.user_info2,
      userInfo3: sessionData.user_info3,
      userInfo4: sessionData.user_info4,
      userType: sessionData.user_type,
      // expire: Date.now() + 1000 * 60 * 60 * 24, // 24시간
    };
    const objString = JSON.stringify(sessionObj);
    window.localStorage.setItem("lewisuserss", objString);
    window.location.href = "/agree";
  };

  const input_Id = useRef(null);
  const inputPw = useRef(null);
  const button = useRef(null);

  function validate() {
    if (!(input_Id.current.value && inputPw.current.value)) {
      button.current.disabled = true;
    } else {
      button.current.disabled = false;
      button.current.style.cursor = "pointer";
    }

    if (!(input_Id.current.value && inputPw.current.value)) {
      button.current.classList.remove("buttonLoginDisabled");
    } else {
      button.current.classList.add("buttonLoginDisabled");
    }
  }

  return (
    <div className="wrapper lewis login">
      <div className="login-box">
        {showTerms ? (
          <>
            <h2>
              <img src="dist/images/login-logo.png" />
            </h2>
            <p>AI 카피라이터 Lewis에 오신걸 환영해요.</p>
            <div className="form-wrap">
              <form method="post" id="login-form">
                <div className="idpwBox">
                  <InputGroup>
                    <Form.Control
                      className="idform userID"
                      style={{
                        backgroundColor: "#ffffff",
                        marginBottom: "0.5rem",
                      }}
                      id="inputId"
                      name="inputId"
                      type="text"
                      ref={input_Id}
                      onKeyUp={validate}
                      placeholder="아이디를 입력해주세요"
                      onChange={(event) => setInputId(event.target.value)}
                      value={inputId}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Form.Control
                      className="pwform userPW"
                      style={{ backgroundColor: "#ffffff" }}
                      id="password"
                      name="password"
                      type="password"
                      ref={inputPw}
                      placeholder="비밀번호를 입력해주세요"
                      onKeyUp={validate}
                      onChange={(event) => setPassword(event.target.value)}
                      value={password}
                      onKeyPress={(
                        event: React.KeyboardEvent<HTMLInputElement>
                      ) => {
                        if (event.key === "Enter") {
                          get_user_info();
                        }
                      }}
                    />
                  </InputGroup>
                  {/* 아이디/비번 불일치
              <span className="alert"><i className="fa fa-exclamation-circle" aria-hidden="true"></i>아이디 혹은 비밀번호가 일치하지 않아요.</span> */}
                </div>
                <button
                  type="button"
                  className="buttonLogin"
                  ref={button}
                  onClick={() => {
                    get_user_info();
                  }}
                >
                  로그인
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  data-toggle="modal"
                  data-target="#modal-default"
                  ref={buttonRef}
                  style={{ display: "none" }}
                >
                  비밀번호 변경
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  data-toggle="modal"
                  data-target="#modal-default"
                  style={{ display: "none" }}
                >
                  비밀번호 찾기
                </button>
                {/* <div style={{ display: "flex", justifyContent: "center" }}>
              <div id="signInDiv"></div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "10px"}}>

              <div id="naverIdLogin"></div>
            </div> */}
              </form>
            </div>
          </>
        ) : (
          <>
            <h2>
              <img src="dist/images/login-logo.png" />
            </h2>
            <p>AI 카피라이터 Lewis에 오신걸 환영해요.</p>
            <div className="form-wrap">
              H-art를 통해서 접속해주시기 바랍니다.
            </div>
          </>
        )}
      </div>

      <footer>
        <p className="copyright">
          © Hyundai Department Store. All rights reserved.
        </p>
      </footer>
      <ChangePassword inputId={inputId} password={password} />
    </div>
  );
};

export default AdminIdLogin;
