import { appConfig } from "@app/config/Config";

export function getSession() {
  const objString = window.localStorage.getItem(appConfig.sessionName);

  if (!objString) return null;

  const obj = JSON.parse(objString);
  if (Date.now() > obj.expire) {
    window.localStorage.removeItem(appConfig.sessionName);
    return null;
  }

  return obj;
}

export function signOut() {
  window.localStorage.removeItem(appConfig.sessionName);
  return true;
}
