import { HttpAdminMainApi } from "@app/interface/admin-main-api";
import React from "react";

interface propsType {
  question: string;
}
//여튼 여기서 구현

const QnAQuestionBox = (props: propsType) => {
  return (
    <tr>
      <td colSpan={4}>
        <div className="quest" style={{ whiteSpace: "pre-wrap" }}>
          <span className="index-icon index-q">Q</span>
          {props.question}
        </div>
      </td>
    </tr>
  );
};

export default QnAQuestionBox;
