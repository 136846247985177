/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, CSSProperties, useEffect } from 'react';
import { ContentHeader } from '@components';
import { HttpAdminMainApi, GetMyLikeListParam } from '@app/interface/admin-main-api';
import { Button, Container, Form, Row, Col, InputGroup } from 'react-bootstrap';
import NoneHistoryBox from '@app/components/history-box/NoneHistoryBox';
import HistoryBox from '@app/components/history-box/HistoryBox';
import { userState } from '@app/interface/MainInterface';
import ClockLoader from 'react-spinners/ClockLoader';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
interface propsType {
  userState: userState;
}

const Mypage02 = (props: propsType) => {
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  let [isLoading, setLoading] = useState(false);

  const adminApi = new HttpAdminMainApi();
  //로그인 정보 (임시))
  const [userState, setUserState] = useState(props.userState);
  //입력값
  const [dataText, setDataText] = useState('');
  const [toDateText, setToDateText] = useState('');
  const [fromDateText, setFromDateText] = useState('');
  const [showFromCalendar, setShowFromCalendar] = useState(false);
  const [showToCalendar, setShowToCalendar] = useState(false);

  //결과값
  const [historyList, setHistoryList] = useState<any>([]);
  //더보기
  const [historyAddList, setHistoryAddList] = useState(1);

  //Box 초기 세팅
  const [historyShow, setHistoryShow] = useState(false);

  //결과값 제목들 히든처리
  const [result01Show, setResult01ShowShow] = useState(false);
  //History 조회 (이름)
  const get_my_like_list = async () => {

    if (validateDate()) {
      // do something
    } else {
      alert("날짜 양식을 확인해주시기 바랍니다.");
      return;
    }

    const param: GetMyLikeListParam = {
        userId: props.userState.id,
        dataText: dataText,
        fromDateText: fromDateText,
        toDateText: toDateText
    };
    setLoading(true);
    const res = await adminApi.get_my_like_list(param);
    if ("" + res.code === "200") {
      setResult01ShowShow(true);
      setHistoryAddList(10);
      setHistoryList(res.response.data_lists);
    } else {
      console.error("get_history_list ERROR : ", res.response.error_msg);
    }
    setLoading(false);
  };

  const fnFromDate = () => {
    setShowFromCalendar(!showFromCalendar);
    setShowToCalendar(false);
  };
  const fnToDate = () => {
    setShowFromCalendar(false);
    setShowToCalendar(!showToCalendar);
  };

  function validateDate() {
    if (!toDateText && !fromDateText) {
      return true;
    }
    if (!toDateText || !fromDateText) {
      return false;
    }
    if (moment(toDateText).isBefore(fromDateText)) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (!props.userState.id) {
      window.location.href = "/login"
    }
    get_my_like_list();
  }, [])  //[] 호출시마다 조회

  useEffect(() => {
    const today = new Date();
    const threeMonthsAgo = new Date(today.setMonth(today.getMonth() - 3));
    const formattedDate = moment(threeMonthsAgo).format('YYYY-MM-DD');
    setToDateText(moment(new Date()).format('YYYY-MM-DD'));
    setFromDateText(formattedDate)
  }, [])  //[] 호출시마다 조회

  return (
    <div>
      <ContentHeader title="AI 카피라이터" />
      <section className="content">
        <Container fluid>
          <Row>
            <div className="col-md-12 pd-0 mb-4">
              <div className="card card-primary card-outline">
                <div className="card-header">
                  <h3 className="card-title">
                    <i className="fas fa-edit cont-tit cpwt" />
                    '좋아요' 해놓은 카피를 검색해 보세요

                  </h3>
                </div>
                <div className="card-body pad table-responsive bg-box">
                  <Row>
                    <Col md={5}>
                      <Form.Group>
                        <h4>제품 / 브랜드
                          <i className="far fa-question-circle nav-icon history" />
                        </h4>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          size="lg"
                          id="dataText"
                          name="dataText"
                          type="text"
                          placeholder=""
                          onChange={event => setDataText(event.target.value)}
                          value={dataText}
                          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                            if (event.key === 'Enter') {
                              get_my_like_list();
                            }
                          }}
                        />

                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group>
                        <h4>생성 일자
                        </h4>
                      </Form.Group>
                      <InputGroup className="mb-2">
                      <Form.Control
                          size="lg"
                          id="fromDateText"
                          name="fromDateText"
                          type="text"
                          disabled
                          placeholder=""
                          onChange={event => setFromDateText(event.target.value)}
                          value={fromDateText}
                        />
                        <Button variant="outline-secondary" id="button-addon2" onClick={fnFromDate}>
                        <i className="fas fa-calendar-alt"></i>
                        </Button>
                      </InputGroup>
                      {showFromCalendar && (
                        <Calendar
                          calendarType='US'
                          onChange={(date:Date) => {
                            setFromDateText(moment(date).format('YYYY-MM-DD'));
                            setShowFromCalendar(false);
                          }}
                          formatDay={(locale, date) => moment(date).format('DD')}
                          value={new Date()}
                          minDetail="month"
                          maxDetail="month"
                          navigationLabel={null}
                          showNeighboringMonth={false}
                          className="mx-auto w-full text-sm border-b react-calendar"
                        />
                      )}
                    </Col>
                    <Col md={2}>
                      <Form.Group>
                        <h4>&nbsp;
                        </h4>
                      </Form.Group>
                      <InputGroup className="mb-2">
                      <Form.Control
                          size="lg"
                          id="toDateText"
                          name="toDateText"
                          type="text"
                          disabled
                          placeholder=""
                          onChange={event => setToDateText(event.target.value)}
                          value={toDateText}
                        />
                        <Button variant="outline-secondary" id="button-addon2" onClick={fnToDate}>
                        <i className="fas fa-calendar-alt"></i>
                        </Button>
                      </InputGroup>
                      {showToCalendar && (
                        <Calendar
                          calendarType='US'
                          onChange={(date:Date) => {
                            setToDateText(moment(date).format('YYYY-MM-DD'));
                            setShowToCalendar(false);
                          }}
                          formatDay={(locale, date) => moment(date).format('DD')}
                          value={new Date()}
                          minDetail="month"
                          maxDetail="month"
                          navigationLabel={null}
                          showNeighboringMonth={false}
                          className="mx-auto w-full text-sm border-b react-calendar"
                        />
                      )}
                    </Col>
                    <Col md={2}>
                    <Form.Group>
                        <h4>&nbsp;
                        </h4>
                      </Form.Group>
                      <Form.Group>
                      <Button
                          className="w-100"
                          variant={isLoading ? "secondary" : "outline-primary"}
                          disabled={isLoading}
                          onClick={() => {
                            get_my_like_list();
                          }}
                        >
                          {isLoading ? (
                            <Row>
                              <Col md={2}>
                                <ClockLoader color="#FFFFFF" size={24} />
                              </Col>
                              <Col md={10}>
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    textAlign:"center",
                                    height: "120%",
                                  }}
                                >
                                  찾고있습니다
                                </span>
                              </Col>
                            </Row>
                          ) : (
                            "찾아볼게요"
                          )}
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            {result01Show ? (
            <div className="col-md-12 mb-4">
              <div className="card card-primary card-outline">
                <div className="card-header">
                  <h3 className="card-title">
                    <i className="fas fa-edit cont-tit time-his" />
                    내가 '좋아요'한 카피
                  </h3>
                </div>
                <div className="card-body pad table-responsive bg-box">
                  <div className="time-scroll-y">
                    {historyShow && <NoneHistoryBox />}
                    {historyList.slice(0, historyAddList).map((item: any, index: number) => (
                      <HistoryBox key={item.range} userState={userState} pk={item.pk} range={item.sub_range}></HistoryBox>
                    ))}
                  </div>
                  <p className="more-btn-wrap">
                    <Button variant="light" onClick={() => { setHistoryAddList(historyAddList + 10) }}>더보기 +</Button>
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          </Row>
        </Container>
      </section>
      {/* /.content */}

    </div>

  );
};

export default Mypage02;
