/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, CSSProperties, useEffect } from "react";
import { userState } from "@app/interface/MainInterface";
interface propsType {
  userState: userState;
}

const Polocy = (props: propsType) => {
  const [showTerms, setShowTerms] = useState(true);
  const handleTermsClick = () => {
    setShowTerms(true);
  };

  const handlePrivacyClick = () => {
    setShowTerms(false);
  };

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 pd-0 mb-4">
              <div className="card card-primary card-outline">
                <div className="card-header">
                  <div className="tab-menu">
                    <button
                      className={`tab-menu-link use ${
                        showTerms ? "is-active" : ""
                      }`}
                      onClick={handleTermsClick}
                      data-content="item-2"
                    >
                      <span data-title="item-2">서비스 이용약관</span>
                    </button>
                    <button
                      className={`tab-menu-link privacy ${
                        !showTerms ? "is-active" : ""
                      }`}
                      onClick={handlePrivacyClick}
                      data-content="item-1"
                    >
                      <span data-title="item-1">개인정보 처리 방침</span>
                    </button>
                  </div>
                </div>

                <div className="card-body pad table-responsive bg-box">
                  <div className="policy-cont">
                    <div className="input-wrap policy-cont">
                      {/* ------------------------------- */}
                      {/*       **서비스 이용약관**       */}
                      {/* ------------------------------- */}
                      {showTerms ? (
                        <div className="policy tab-bar-content is-active">
                          <dl>
                            <dt>제 1장 총칙</dt>
                            <dd>
                              <strong>제 1조(목적)</strong>
                              <br />본 약관은 주식회사 현대아이티앤이(이하
                              “회사”)가 운영하는 루이스(LEWIS) 및 이에 부수하는
                              제반 서비스(통칭 이하 “서비스”)를 이용함에 있어
                              서비스 이용자의 이용 조건 및 절차, 양 당사자 간의
                              권리·의무 등을 규정하는데 그 목적이 있습니다.
                            </dd>
                            <dd>
                              <strong>제 2조(정의)</strong>
                              <br />본 약관에서 사용하는 주요 용어의 정의는
                              다음과 같습니다.
                              <br />
                              1. 회원: 서비스를 제공받기 위하여 정해진 가입
                              절차에 따라 절차를 진행하여 정상적으로 루이스
                              서비스를 이용할 수 있는 권한을 부여 받은 개인 또는
                              단체를 말합니다.
                              <br />
                              2. 회원이 루이스(LEWIS)를 이용할 수 있는 인터넷
                              사이트(https://www.ai-lewis.com)를 말합니다.
                              <br />
                              3. 사용자 아이디(ID): 회원 식별과 서비스 이용을
                              위하여 회원이 정하고 웹사이트가 승인하는 문자와
                              숫자의 조합입니다.
                              <br />
                              4. 비밀번호(Password): 회원이 자신의 비밀 보호를
                              위하여 선정한 문자와 숫자의 조합입니다.
                              <br />
                              5. 콘텐츠: 회사가 루이스(LEWIS)를 통해 제공하는
                              기능으로 생성된 문장 또는 문장 콘텐츠(문자, 부호
                              등)입니다.
                            </dd>
                            <dd>
                              <strong>제 3조(이용약관의 효력 및 변경)</strong>
                              <br />
                              1. 본 약관의 내용은 회원이 알 수 있도록 웹사이트
                              화면에 게시하고, 그 내용이 웹사이트에 게시되거나
                              기타의 방법으로 회원에게 공지함으로써 효력이
                              발생합니다.
                              <br />
                              2. 본 약관은 관련 법을 위배하지 않는 범위 내에서
                              회사에 의해 개정 가능하며 약관을 개정하고자 할
                              경우에는 약관이 개정된다는 사실과 개정된 내용 등을
                              적용일자 7일 전까지 공지합니다. 다만, 회원에게
                              불리하게 약관내용이 변경되는 경우에는 최소 30일
                              이상의 사전 유예기간을 두고 고지합니다.
                              <br />
                              3. 변경된 약관의 효력 발생일 이후에 회원이
                              루이스(LEWIS)를 계속 이용할 경우 변경된 이용약관의
                              모든 변경 사항에 동의한 것으로 간주됩니다.
                              <br />
                              4. 이 약관에 작성 및 정하지 아니한 사항과 이 약관
                              해석에 전자상거래 등에서의 소비자보호에 관한 법률,
                              약관의 규제에 관한 법률, 기타 관계법령 또는
                              상관례에 따릅니다.
                            </dd>
                          </dl>

                          <dl>
                            <dt>제 2장 서비스 이용 계약</dt>
                            <dd>
                              <strong>제 4조 (서비스 이용 계약의 체결)</strong>
                              <br />
                              1. 이용 계약은 이용 신청자가 회원가입 절차 중
                              약관의 내용에 동의하고 이에 대하여 회사가
                              승낙함으로써 체결됩니다.
                              <br />
                              2. 회사는 필요에 따라 회원에게 실명 확인 및 본인
                              인증 요청을 할 수 있으며, 그 절차와 방식은 관계
                              법령에 따릅니다.
                              <br />
                              3. 실명이나 실제 정보를 입력하지 않은 회원은
                              법적인 보호를 받을 수 없으며, 서비스 이용이
                              불가합니다.
                              <br />
                              4. 회원은 회원가입시 기재한 사항이 변경되었을
                              경우, 온라인으로 수정을 하거나 전자우편, 기타
                              방법으로 회사에 그 변경사항을 알려야 합니다.
                              <br />
                              5. 제 4항의 변경사항을 회사에 알리지 않아 발생한
                              불이익에 대하여 회사는 책임지지 않습니다.
                            </dd>
                            <dd>
                              <strong>제 5조 (서비스 이용 신청)</strong>
                              <br />
                              1. 회원으로 웹사이트를 가입하여 본 서비스를
                              이용하고자 하는 자는 회사에서 요청하는 기본
                              정보(필수 정보)를 기입하여야 합니다. 이후 회원이
                              된 자가 기입한 개인 정보는 개인정보보호방침에 따라
                              관리됩니다.
                              <br />
                              2. 모든 회원은 반드시 회원 본인의 정보를 등록 및
                              제공하여야 합니다. 실명이나 실제 정보를 입력하지
                              않은 사용자는 법적인 보호를 받을 수 없으며, 서비스
                              이용이 불가합니다. 타인의 정보를 도용하거나 허위
                              정보를 등록하는 경우 관계 법령에 따라 처벌받을 수
                              있습니다.
                              <br />
                              3. 회사는 필요에 따라 회원이 등록한 정보에 대하여
                              확인 조치를 취할 수 있습니다. 회원은 회사의 확인
                              조치에 대하여 적극적으로 협력하여야 하며, 만일
                              이를 준수하지 않을 경우 회사는 회원이 등록한
                              정보를 부정한 것으로 간주하여 처리할 수 있습니다.
                              <br />
                              4. 루이스(LEWIS)는 1인 1계정을 원칙으로 하고
                              있으며, 하나의 회원 계정으로 동시에 중복 로그인이
                              불가합니다.
                            </dd>
                            <dd>
                              <strong>제 6조 (서비스 이용의 제한)</strong>
                              <br />
                              1. 회사는 아래 사항에 해당하는 경우에 회원의
                              서비스 이용을 제한하거나 이용 계약 승낙을 철회할
                              수 있습니다.
                              <span className="indent">
                                ① 회원 정보를 허위로 기재하여 신청한 경우
                                <br />② 타인의 정보를 도용하여 신청한 경우
                                <br />③ 법령 위반 또는 사회의 통념적 질서 또는
                                미풍 양속을 저해할 목적으로 신청한 경우
                                <br />④ 부정한 용도로 서비스를 이용하는 경우
                                <br />⑤ 기타 현행 법령에 위배되는 목적으로
                                서비스를 이용하는 경우
                                <br />⑥ 이용약관 위반으로 회원 자격을 박탈당하고
                                1년 이내에 재가입 하는 경우
                                <br />⑦ 기타 사이트 소정의 이용신청요건을
                                충족하지 못하거나 규정한 제반 사항을 위반하여
                                신청하는 경우
                                <br />⑧ 회원이 본 약관 제10조의 의무를 다하지
                                않거나 회사의 서비스 운영 정책을 위반하는 경우
                              </span>
                              2. 회사는 아래 사항에 해당하는 경우에 그 제한
                              사유가 해소될 때까지 이용 계약 체결을 유보할 수
                              있습니다.
                              <br />
                              <span className="indent">
                                ① 서비스 관련 설비에 여유가 없는 경우
                                <br />② 기술상 또는 업무상 지장이 있는 경우
                                <br />③ 기타 회사의 사유로 필요하다고 인정되는
                                경우
                              </span>
                            </dd>
                            <dd>
                              <strong>
                                제 7조 (서비스 이용을 위한 기술적 요구 사항)
                              </strong>
                              <br />
                              루이스 서비스를 이용하기 위하여 아래의 필수 사양이
                              충족되어야 합니다. 또한 사용자가 해당 사양에
                              충족되지 못한 사유를 문제로 서비스 사용에 이의를
                              제기할 시 회사는 아무런 책임이 없음을 밝힙니다.
                              <br />
                              필수 사양
                              <br />- 웹브라우저: 크롬(Chrome) 또는
                              사파리(Safari)에 최적화되어 있습니다.
                              <br />- 모바일: 미지원으로 웹브라우저에서 사용
                              가능합니다.
                            </dd>
                            <dd>
                              <strong>제 8조 (개인정보 보호)</strong>
                              <br />
                              1. 회사는 관계 법령이 정하는 바에 따라 회원의
                              개인정보를 보호할 의무가 있습니다. 개인정보의 보호
                              및 사용에 대해서는 관련 법령 및 회사의
                              개인정보보호방침이 적용됩니다. 단, 회사의 서비스
                              이외의 연결된 사이트에서는 회사의
                              개인정보보호방침이 적용되지 않습니다. 또한, 회원은
                              아이디(ID) 또는 비밀번호 등이 타인에게 노출되지
                              않도록 철저히 관리해야 하며 회원의 귀책사유로 인해
                              노출된 정보에 대해서 회사는 책임지지 않습니다.
                              <br />
                              2. 회사는 다음 각 호의 경우 법이 허용하는 범위
                              내에서 회원의 개인정보를 제3자에게 제공할 수
                              있습니다.
                              <span className="indent">
                                ① 수사기관이나 기타 정부기관으로부터 정보 제공을
                                요청 받은 경우
                                <br />② 회원의 법령 또는 약관의 위반을 포함하여
                                부정행위 확인 등의 정보 보호 업무를 위해 필요한
                                경우
                                <br />③ 기타 법률에 의해 요구되는 경우
                              </span>
                              3. 회사는 필요시 루이스(LEWIS)의 사용자 아이디(ID)
                              및 회원의 개인정보를 회사 또는 회사가 운영하는
                              기타 서비스의 회원 개인정보와 관련하여 내부 방침에
                              따라 열람 및 사용할 수 있습니다.
                            </dd>
                          </dl>

                          <dl>
                            <dt>제 3장 계약 당사자의 의무</dt>
                            <dd>
                              <strong>제 9조 (회사의 의무)</strong>
                              <br />
                              1. 회사는 법령과 이 약관이 금지하거나 미풍양속에
                              반하는 행위를 하지 않으며, 이 약관이 정하는 바에
                              따라 지속적이고 안정적으로 서비스를 제공하기
                              위하여 최선을 다해 노력합니다.
                              <br />
                              2. 회사는 회원이 안전하게 서비스를 이용할 수
                              있도록 개인정보보호를 위해 보안시스템을 갖추어야
                              하며 개인정보처리방침을 공시하고 준수합니다.
                              <br />
                              3. 회사는 회원으로부터 제기되는 의견이나 개선
                              요청이 정당하다고 인정될 경우 즉시 처리하는 것을
                              원칙으로 합니다. 단, 즉시 처리가 곤란한 경우에는
                              사용자에게 그 사유와 처리 일정을 통보하여야
                              합니다.
                            </dd>
                            <dd>
                              <strong>제 10조 (회원의 의무)</strong>
                              <br />
                              1. 회원은 회원가입 신청 또는 회원정보 변경 시 모든
                              사실에 근거하여 작성해야 합니다. 위반 시 서비스
                              이용과 관련하여 일체의 권리를 주장할 수 없습니다.
                              2. 회원은 이 약관 및 회사의 공지사항, 웹사이트
                              이용 안내 등을 숙지하고 준수해야 하며 다음 각 호에
                              해당하는 행위를 해서는 안되며, 할 경우 회사는
                              서비스 이용 제한 및 적법한 조치를 포함하여 제재를
                              가할 수 있습니다.
                              <br />
                              <span className="indent">
                                ① 회원가입 신청 또는 회원정보 변경 시 허위
                                내용을 기재하는 행위
                                <br />② 타인의 정보 또는 결제 수단을 도용하는
                                행위
                                <br />③ 회사로부터 허가를 받지 않고 회사의
                                서버를 해킹하거나 회사의 웹사이트 또는 서비스,
                                게시된 정보 전체 또는 일부분을 임의로
                                변경하거나, 회사의 서비스를 비정상적인 방법으로
                                사용하는 등의 행위
                                <br />④ 본인이 아닌 제 3자에게 계정을 공유,
                                양도하는 행위
                                <br />⑤ 정치활동, 선거운동 등의 용도로 서비스를
                                이용하는 행위
                                <br />⑥ 기타 제3자의 저작권 등 지적재산권을
                                침해하거나 제 3자의 명예 손상 및 업무를 방해하는
                                행위
                                <br />⑦ 회사의 서비스를 이용하여 얻은 정보를
                                무단으로 복제, 유통, 조장하거나 상업적으로
                                이용하는 행위, 알려지거나 알려지지 않은 버그를
                                악용하여 서비스를 이용하는 행위
                                <br />⑧ 외설 또는 폭력적인 메시지/화상/음성/기타
                                공서양속에 반하는 정보를 홈페이지에 공개 또는
                                게시하는 행위
                                <br />⑨ 공공질서 및 미풍양속에 위반되거나 범죄적
                                행위에 결부되는 것으로 판단되는 행위
                                <br />⑩ 기타 관계 법령에 위배되는 행위
                              </span>
                              3. 회원은 관계법령, 이 약관의 규정, 이용안내 및
                              서비스와 관련하여 공지한 주의사항, 회사가 통지하는
                              사항 등을 준수하여야 하며, 기타 회사의 업무에
                              방해되는 행위를 하여서는 안 됩니다.
                              <br />
                              4. 회원은 본인의 아이디, 비밀번호 등에 대한 관리
                              책임을 부담하며, 회원의 관리소홀로 인하여 발생한
                              문제에 대하여 회사는 책임을 지지 않습니다.
                              <br />
                              5. 루이스(LEWIS)를 통해 생성된 콘텐츠에 대한 사실
                              관계 및 법적, 윤리적 문제 확인, 내용 검증 및
                              사용에 대한 모든 책임은 회원에게 있으며, 회사 및
                              회원은 제3자의 지적 재산권을 침해해서는 안됩니다.
                              <br />
                              6. 회원은 루이스(LEWIS)를 악의적인 목적으로 이용할
                              수 없습니다. 악의적인 목적이란 해당 서비스를
                              사용하는 과정에서 고의적인 값을 입력하여 차별적
                              의견 또는 부당한 가치 판단 등 편향적인 결과값을
                              발생시키는 것이 대표적이며, 그 밖에도 회원이
                              악의적인 의도를 가지고 해당 서비스를 사용하여
                              발생시키는 모든 문제를 포함합니다.
                            </dd>
                          </dl>

                          <dl>
                            <dt>제 4장 서비스의 제공 및 이용</dt>
                            <dd>
                              <strong>제 11조 (서비스 이용)</strong>
                              <br />
                              1. 서비스 이용은 회사의 업무 또는 기술 상의 특별한
                              지장이 없는 한 연중무휴, 1일 24시간 운영을
                              원칙으로 합니다. 단, 회사의 업무상 또는 기술상의
                              이유로 서비스를 일시 중단 할 수 있습니다. 이러한
                              경우 해당 내용에 대하여 루이스 웹사이트 공지 또는
                              기타의 방법으로 사전 또는 사후에 이를 공지합니다.
                              <br />
                              2. 회원은 본 약관에 규정된 사항을 준수하여
                              서비스를 이용하며, 이 약관에 명시되지 않은 서비스
                              이용에 대한 사항은 회사가 정해 웹사이트에
                              게시하거나 또는 별도로 공지하는 내용에 따릅니다.
                              <br />
                              3. B2B 서비스를 활용하는 경우, 서비스 이용 약관과
                              회사간 계약서에 명시된 항목이 상이할 경우 계약서의
                              조항을 따릅니다.
                            </dd>
                            <dd>
                              <strong>제 12조 (서비스 제공의 중지)</strong>
                              <br />
                              1. 회사는 다음 각 호에 해당하는 경우 서비스의 전부
                              또는 일부의 제공을 중지할 수 있습니다.
                              <span className="indent">
                                ① 긴급한 시스템 점검, 증설, 교체, 고장 혹은
                                오작동을 일으키는 경우
                                <br />② 국가 비상사태, 정전, 천재지변 등의
                                불가항력적인 사유가 있는 경우
                                <br />③ 전기통신사업법에 상에 규정된
                                기간통신사업자/인터넷망사업자가 서비스를
                                중지했을 경우
                                <br />④ 서비스 이용의 폭주 등으로 정상적인
                                서비스 이용에 지장이 있는 경우
                                <br />⑤ 회사가 서비스 제공에 이용하는 네이버
                                운영 ‘CLOVA STUDIO’ 서버에 문제가 발생하는
                                경우를 포함하여 외부 제휴업체 측 사유로 정상적인
                                서비스 제공이 어려운 경우
                              </span>
                              2. 회사는 위 규정 이외에도 루이스(LEWIS) 정책 및
                              관련 규정, 회사의 공식적인 결정에 의거하여
                              서비스의 이용을 제한하거나 정지할 수 있습니다.
                              회사의 결정에 의한 서비스 제한 또는 정지는 사전에
                              공지하는 것을 원칙으로 하나, 부득이한 경우가
                              발생할 수 있습니다.
                              <br />
                              3. 회사는 영업양도, 분할, 합병 등에 따른 영업의
                              폐지, 콘텐츠 제공의 계약만료, 당해 서비스의 현저한
                              수익 악화 등 경영상의 중대한 사유로 인해 서비스를
                              지속하기 어려운 경우에는 서비스를 중단할 수
                              있습니다. 이 경우 중단일자 30일 이전까지 중단일자
                              및 사유를 앱 초기화면 또는 그 연결화면, 회사
                              홈페이지 등 기타 서비스 제공 화면을 통해 공지하고
                              회원에게 통지합니다.
                              <br />
                              4. 회사는 제1항 각 호의 사유에 의하여 서비스가
                              중단된 경우 또는 서비스 중단에 대하여 회사가 고의
                              또는 과실이 없음을 입증하는 경우에는 회원이 입은
                              손해에 대해 배상 책임을 지지 않습니다.
                            </dd>
                            <dd>
                              <strong>제 13조 (저작권 및 서비스 관리)</strong>
                              <br />
                              1. 회원이 루이스(LEWIS)를 활용해 만든 콘텐츠 및
                              편집 혹은 저장한 ‘사용자 결과물’이 타인의 저작권에
                              위반되는 등의 문제가 발생했을 경우, 이에 대한 모든
                              책임은 회사에 있지 않고 이를 외부에서 사용한 해당
                              회원에 있습니다.
                              <br />
                              2. 회원이 루이스(LEWIS)를 통해 생성된 결과값을
                              외부에 공개하는 과정에서 회사에 피해를 입힌 경우,
                              그에 대한 법률상 책임을 부담합니다.
                              <br />
                              3. 회원은 자신이 서비스 이용과정에서 입력하는 글,
                              기타 자료가 제 3자의 지적재산권 및 기타 권리를
                              침해하지 않는다는 것을 보증합니다. 회원이 이를
                              위반하여 제 3자와 회원 또는 회사 사이에 소송,
                              이의제기, 권리주장 기타 일체의 분쟁이 발생하는
                              경우, 회원은 회사를 면책하고 해당 분쟁을
                              해결하여야 하며 이로 인해 회사가 손해를 입은 경우
                              해당 손해를 배상하여야 합니다.
                              <br />
                              4. 회사는 서비스 품질 관리를 위하여 생성된
                              콘텐츠에 대하여 정기적인 사후 검토 작업을
                              진행합니다. 회사의 정책 또는 법규를 위반한다고
                              합리적으로 판단되는 콘텐츠는 삭제하거나 게시를
                              거부할 수 있습니다.
                              <br />
                              5. 회원의 입력값과 콘텐츠는 더 나은 서비스 제공을
                              위한 엔진 개선에 활용될 수 있으며 모델 평가에
                              사용될 수 있습니다.
                              <br />
                              6. 루이스(LEWIS)는 사용자에게 친근하고 유용한 AI를
                              지향하고 있습니다. 그 과정에서 올바른 표현 도출과
                              개인정보 보호를 우선으로 하고 있으며, 잘못된
                              정보와 편항된 정보가 노출되지 않도록 노력하고
                              있습니다.
                              <br />
                              7. 본 규정을 준수하지 않은 회원은 별도의 공지 없이
                              이용이 정지되거나 제한될 수 있으며, 회사는 이에
                              대한 책임을 부담하지 않습니다
                              <br />
                              8. 제 13 조는 회사가 서비스를 운영하는 동안
                              유효하며, 회원 탈퇴 후에도 지속적으로 적용됩니다.
                            </dd>
                            <dd>
                              <strong>제 14조 (정보의 제공)</strong>
                              <br />
                              회사는 회원이 서비스 이용 중 필요하다고 인정되는
                              다양한 정보에 대하여 서비스 내의 화면이나
                              전자우편(E-mail) 등의 방법으로 회원에게 정보를
                              제공할 수 있습니다. 단, 광고성 정보에 해당하는
                              경우 회원으로부터 수신 동의를 받습니다.
                            </dd>
                            <dd>
                              <strong>
                                제 15조 (계약 사항의 변경 및 해지)
                              </strong>
                              <br />
                              회원은 웹사이트 내에서 서비스 이용 계약을 해지할
                              수 있으며, 회원이 이용 계약을 해지하고자 하는
                              때에는 해당 회원 본인이 직접 웹사이트에 접속하여
                              가입해지(회원 탈퇴)를 진행해야 합니다. 회원의 계약
                              해지 후에는 그 동안 생산한 콘텐츠 등 모든 기록이
                              다 삭제되어, 복구가 불가합니다. 또한, 해당일부터는
                              탈퇴 회원으로써 서비스를 이용하실 수 없습니다.
                              회사는 가입 탈퇴 후 삭제된 작업물이
                              루이스(LEWIS)에서 제작되었다는 것을 입증하거나
                              책임 지지 않으며, 해지 완료되어 소실된 사용자의
                              모든 콘텐츠 등에 대한 책임은 원칙적으로 해당
                              사용자에게 있습니다.
                            </dd>
                          </dl>

                          <dl>
                            <dt>제 5장 기타</dt>
                            <dd>
                              <strong>제 16조 (분쟁 해결)</strong>
                              <br />
                              1. 회사는 회원이 제기하는 정당한 의견이나 불만을
                              반영하고 회사가 납득할만한 피해 발생시 그 피해에
                              대한 보상을 처리하기 위해 필요 시 피해 보상 처리
                              회의를 소집 • 운영합니다.
                              <br />
                              2. 회사는 회원으로부터 제출되는 불만사항 및 의견을
                              우선으로 수용하고 내부 회의를 통해 관련 사항을
                              처리합니다. 신속한 처리가 곤란한 경우 사용자에게
                              사유와 진행사항을 공지합니다.
                              <br />
                              3. 회사와 회원 간에 발생한 전자상거래 분쟁과
                              관련하여 사용자의 피해구제신청이 있는 경우에는
                              공정거래위원회 또는 시 • 도지사가 의뢰하는
                              분쟁조정기관의 조정에 따를 수 있습니다.
                            </dd>
                            <dd>
                              <strong>제 17조 (면책 및 손해배상)</strong>
                              <br />
                              1. 회사 또는 회원은 본 약관을 위반하여 상대방에게
                              손해를 입힌 경우 그 손해를 배상할 책임이 있습니다.
                              다만, 고의 또는 과실이 없는 경우 그러하지 아니
                              합니다.
                              <br />
                              2. 회사는 천재지변, 불가항력 기타 당사의 합리적인
                              통제범위를 벗어난 사유로 인하여 인터넷상 서비스를
                              제공할 수 없는 경우에는 그에 대한 책임을 부담하지
                              않습니다.
                              <br />
                              3. 회사는 회원의 귀책사유로 인하여 서비스를 제공할
                              수 없는 경우에는 그에 대한 책임을 부담하지
                              않습니다.
                              <br />
                              4. 회사는 회원이 서비스를 이용함으로써 기대되는
                              수익을 얻지 못하거나 서비스를 통해 얻은 자료를
                              이용하여 발생한 손해에 대해서는 책임을 부담하지
                              않을 수 있습니다.
                              <br />
                              5. 회원이 화면에 게재한 정보, 자료, 사실 등의
                              내용에 관한 신뢰도 또는 정확성에 대하여는 해당
                              회원이 책임을 부담하며, 당사는 내용의 부정확 또는
                              허위로 인해 회원 또는 제3자에게 발생한 손해에
                              대하여는 아무런 책임을 부담하지 않습니다.
                              <br />
                              6. 루이스(LEWIS)는 하드웨어 및 소프트웨어적인
                              오류가 발생할 수 있습니다. 회사는 최선을 다하여
                              오류의 발생을 막기 위하여 노력하겠으나, 오류 및
                              장애의 발생으로 인한 사용자의 데이터의 유실,
                              물질적/정신적 손실, 비즈니스의 중단 등으로 인한
                              피해에 대하여 회사는 책임을 지지 않습니다.
                              <br />
                              7. 회사는 사전 통보 없이 웹사이트 내의 콘텐츠,
                              소프트웨어, 게시물 및 디자인 등을 변경할 수
                              있습니다.
                            </dd>
                            <dd>
                              <strong>제 18조 (약관 외 준칙)</strong>
                              <br />본 약관에서 정하지 아니한 사항과 이 약관의
                              해석에 관하여는 관계법령 및 상관례에 따릅니다.
                            </dd>
                            <dd>
                              <strong>제 19조 (분쟁조정 및 관할법원)</strong>
                              <br />본 약관에 의한 서비스 이용과 관련한 제반
                              분쟁 및 소송은 회원의 주소를 관할하는 법원에
                              의하고, 주소가 없는 경우에는 거소를 관할하는
                              지방법원의 전속관할로 합니다. 다만, 제소 당시
                              이용자의 주소 또는 거소가 분명하지 않거나 외국
                              거주자의 경우에는 민사소송법상의 관할법원에
                              제기합니다.
                            </dd>
                          </dl>

                          <dl>
                            <dt></dt>
                            <dd>
                              <b>
                                부칙
                                <br />본 서비스 이용약관은 2023년 3월
                                2일(목)부터 시행합니다.
                              </b>
                            </dd>
                          </dl>
                        </div>
                      ) : (
                        <div className="policy tab-bar-content is-active">
                          <dl>
                            <dt>1. 총칙</dt>
                            <dd>
                              루이스(Lewis) 서비스 및 이에 부수하는 제반
                              서비스를 운영하는 현대백화점/현대IT&amp;E(이하
                              “회사”)는 개인정보 보호법 등 관련 법령에 의거하여
                              개인정보 처리방침(이하 “처리방침”)을 제정하고 이를
                              준수하고 있습니다. “회사”는 “처리방침”을 통해 어떤
                              개인정보 항목을, 어떤 목적으로 수집하고 있으며,
                              어떻게 보호조치가 되고 있는지에 대해 알려 드리며
                              이는 관련 법령 및 지침 “회사” 내부 운영방침의
                              변경에 따라 변경될 수 있습니다. 만약 변경사항이
                              발생할 경우에는 관련 법령이 정하는 방법에 따라
                              고지함을 알려 드립니다.
                            </dd>
                          </dl>
                          <dl>
                            <dt>2. 개인정보의 처리 목적</dt>
                            <dd>
                              회사는 다음의 목적을 위하여 개인정보를 처리합니다.
                              <br />
                              1) 회원 가입 및 관리: 회원가입의사 확인, 본인
                              식별인증, 연령인증, 회원자격 유지 및 관리, 각종
                              고지통지
                              <br />
                              2) 고충처리: 이용자의 신원 확인, 고충사항 확인,
                              사실조사를 위한 연락통지, 처리결과 통보
                              <br />
                              3) 서비스 개선 및 개발: 기존 서비스 개선 및 신규
                              서비스, 맞춤형 서비스 개발
                              <br />
                              4) 가명정보의 활용: 통계작성, 과학적 연구, 공익적
                              기록보존 등을 위한 가명처리 및 가명정보의 활용
                            </dd>
                          </dl>
                          <dl>
                            <dt>3. 처리하는 개인정보 항목</dt>
                            <dd>
                              1) 회사는 서비스 이용자에 대하여 다음의
                              개인정보항목을 수집하여 처리하고 있습니다.
                              <span className="indent">
                                - 회원 가입 시 기본수집사항(필수항목): 이메일,
                                로그인ID, 이름, 프롬프트에 입력한 텍스트 내용
                                정보와 AI 엔진이 출력한 텍스트 내용 정보
                                <br />- 중복 계정 생성을 방지하기 위한
                                수집사항(필수항목) : 휴대전화번호
                                <br />- 서비스 이용과정에서 아래 개인정보 항목이
                                자동으로 : IP주소, 쿠키, 서비스 이용 기록(방문
                                및 이용기록, 불량 이용기록 등), 기기정보(휴대폰
                                모델명, OS 명 및 버전정보), 광고식별자
                                <br />- 고충처리 시: 이용자로부터 위 각 정보 중
                                필요한 항목 및 해당 고충처리에 필요한 별개
                                항목을 수집 및 처리
                              </span>
                            </dd>
                          </dl>
                          <dl>
                            <dt>4. 개인정보의 처리 및 보유기간</dt>
                            <dd>
                              1) 회사는 개인정보의 수집 및 이용목적이
                              달성되거나, 이용자가 서비스를 탈퇴 또는 이용자격을
                              상실할 경우에는 별도의 요청이 없더라도 수집된
                              이용자의 정보를 지체없이 삭제 및 파기합니다. 다만,
                              회원 탈퇴 또는 이용자격 상실에도 불구하고 다음의
                              정보에 대해서는 아래의 이유로 보존합니다.
                              <span className="indent">
                                - 관계 법령 위반에 따른 수사조사 등이 진행 중인
                                경우에는 해당 수사·조사 종료 시까지
                                <br />- 회사가 이용약관에 따라 서비스 이용계약을
                                해지한 경우 부정한 재가입 및 서비스 이용을
                                방지하기 위하여 서비스 운영시까지
                              </span>
                              2) 전항에도 불구하고 회사는 다음의 사유에 해당하는
                              경우에는 해당 기간 종료 시까지 보존합니다.
                              <span className="indent">
                                - 서비스 이용 관련 개인정보 (로그기록):
                                「통신비밀보호법」상 보존기간인 3개월
                                <br />- 계약 또는 청약철회 등에 관한 기록 및
                                대금결제 및 재화 등의 공급에 관한 기록:
                                「전자상거래 등에서의 소비자보호에 관한 법률」상
                                보존기간인 5년
                                <br />- 소비자의 불만 또는 분쟁처리에 관한 기록:
                                「전자상거래 등에서의 소비자보호에 관한 법률」상
                                보존기간인 3년
                                <br />- 표시 광고에 관한 기록: 「전자상거래
                                등에서의 소비자보호에 관한 법률」상 보존기간인
                                6개월
                                <br />- 세법이 규정하는 모든 거래에 관한 장부 및
                                증빙서류: 「국세기본법」상 보존기간인 5년
                              </span>
                              3) 회사는 1년 또는 이용자가 별도로 정한 기간(3년)
                              동안 서비스를 이용하지 않은 이용자의 개인정보를
                              별도로 분리보관 또는 삭제하여 관리합니다.
                            </dd>
                          </dl>
                          <dl>
                            <dt>5. 개인정보의 제3자 제공</dt>
                            <dd>
                              회사는 이용자의 동의를 받거나 개인정보 보호법 또는
                              다른 법률의 특별한 규정이 있는 경우에만 개인정보를
                              제3자에게 제공합니다.
                            </dd>
                          </dl>
                          <dl>
                            <dt>6. 개인정보처리의 위탁</dt>
                            <dd>
                              1) 회사는 원활한 개인정보 업무처리를 위하여 다음과
                              같이 개인정보 처리업무를 위탁하고 있습니다.
                              <table>
                                <tbody>
                                  <tr>
                                    <td>위탁받은 자(수탁자)</td>
                                    <td>위탁하는 업무의 내용</td>
                                  </tr>
                                  <tr>
                                    <td>Amazon Web Services Inc.</td>
                                    <td>개인정보 처리를 위한 인프라 관리</td>
                                  </tr>
                                </tbody>
                              </table>
                              2) 회사는 위탁계약 체결 시 개인정보 보호법
                              제26조에 따라 위탁업무 수행목적 외 개인정보
                              처리금지, 기술적∙관리적 보호조치, 재위탁 제한,
                              수탁자에 대한 관리∙감독 등 책임에 관한 사항을
                              계약서 등 문서에 명시하고, 수탁자가 개인정보를
                              안전하게 처리하는지를 감독하고 있습니다.
                            </dd>
                          </dl>
                          <dl>
                            <dt>
                              7. 수집목적과 합리적으로 관련된 범위 내의 개인정보
                              이용 및 제공
                            </dt>
                            <dd>
                              회사는 당초 수집 목적과 합리적인 범위 내에서 아래
                              각 기준을 고려하여, 이용자의 동의 없이 개인정보를
                              이용 또는 제3자에게 제공할 수 있습니다.
                              <span className="indent">
                                - 당초 수집 목적과 관련성이 있는지 여부: 당초
                                수집 목적과 추가적 이용·제공 목적이 성질이나
                                경향에 있어 연관이 있는지 등을 고려하여 따라
                                판단
                                <br />- 개인정보를 수집한 정황 또는 처리 관행에
                                비추어 볼 때 개인정보의 추가적인 이용 또는
                                제공에 대한 예측 가능성이 있는지 여부:
                                개인정보처리자와 이용자 간의 관계, 기술 수준 및
                                발전 속도, 상당한 기간동안 정립된 일반적인
                                사정(관행) 등을 고려하여 판단
                                <br />- 이용자의 이익을 부당하게 침해하는지
                                여부: 추가적인 이용 목적과의 관계에서 이용자의
                                이익이 실질적으로 침해되는지 및 해당 이익 침해가
                                부당한지 등을 고려하여 판단
                                <br />- 가명처리 또는 암호화 등 안전성 확보에
                                필요한 조치를 하였는지 여부: 침해 가능성을
                                고려한 안전 조치가 취해지는지 등을 고려하여 판단
                              </span>
                            </dd>
                          </dl>
                          <dl>
                            <dt>
                              8. 이용자와 법정대리인의 권리·의무 및 행사방법
                            </dt>
                            <dd>
                              ① 이용자는 회사에 대해 언제든지 개인정보
                              열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수
                              있습니다.
                              <br />② 제1항에 따른 권리 행사는 서면, 전자우편
                              등을 통하여 하실 수 있으며, 회사는 이에 대해 지체
                              없이 조치하겠습니다.
                              <br />③ 제1항에 따른 권리 행사는 이용자의
                              법정대리인이나 위임을 받은 자 등 대리인을 통하여서
                              하실 수 있습니다. 이 경우 수임인에 대한 위임사실을
                              확인할 수 있는 위임장을 제출하셔야 합니다.
                              <br />④ 개인정보 보호법 등 관계 법령에서 정하는
                              바에 따라 이용자의 개인정보
                              열람·정정·삭제·처리정지 요구 등의 권리 행사가
                              제한될 수 있습니다.
                              <br />⑤ 개인정보의 정정 및 삭제 요구는 다른
                              법령에서 그 개인정보가 수집 대상으로 명시되어 있는
                              경우에는 그 삭제를 요구할 수 없습니다.
                              <br />⑥ 회사는 이용자 권리에 따른 열람의 요구,
                              정정·삭제의 요구, 처리정지의 요구 시 열람 등
                              요구를 한 자가 본인이거나 정당한 대리인인지를
                              확인합니다.
                            </dd>
                          </dl>
                          <dl>
                            <dt>9. 개인정보의 파기</dt>
                            <dd>
                              ① 회사는 개인정보 보유기간의 경과, 처리목적 달성
                              등 개인정보가 불필요하게 되었을 때에는 지체없이
                              해당 개인정보를 파기합니다.
                              <br />② 이용자로부터 동의 받은 개인정보 보유기간이
                              경과하거나 처리목적이 달성되었음에도 불구하고
                              제3조 제2항에 기재된 법령에 따라 개인정보를 계속
                              보존하여야 하는 경우에는, 해당 개인정보를 별도의
                              데이터베이스(DB)로 옮기거나 보관장소를 달리하여
                              보존합니다.
                              <br />③ 개인정보 파기의 절차 및 방법은 다음과
                              같습니다.
                              <span className="indent">
                                1. 파기절차: 회사는 파기 사유가 발생한
                                개인정보를 선정하고, 회사의 개인정보
                                보호책임자의 승인을 받아 개인정보를 파기합니다.
                                <br />
                                2. 파기방법: 회사는 전자적 파일 형태로
                                기록·저장된 개인정보는 기록을 재생할 수 없도록
                                기술적 방법을 이용하여 파기하며, 종이 문서에
                                기록·저장된 개인정보는 분쇄기로 분쇄하거나
                                소각하여 파기합니다.
                              </span>
                            </dd>
                          </dl>
                          <dl>
                            <dt>10. 개인정보의 안전성 확보조치</dt>
                            <dd>
                              회사는 개인정보의 안전성 확보를 위해 다음과 같은
                              조치를 취하고 있습니다.
                              <span className="indent">
                                1. 관리적 조치: 내부관리계획 수립·시행, 정기적
                                직원 교육 등
                                <br />
                                2. 기술적 조치: 해킹 등에 대비한 기술적
                                대책개인정보의 암호화, 접속기록의 보관 및 위변조
                                방지 등
                                <br />
                                3. 물리적 조치: 서버실, 자료보관실 등의 접근통제
                                등
                              </span>
                            </dd>
                          </dl>
                          <dl>
                            <dt>
                              11. 개인정보 자동 수집 장치의 설치∙운영 및 거부에
                              관한 사항
                            </dt>
                            <dd>
                              회사는 이용자에게 개별적인 맞춤서비스를 제공하기
                              위해 아래와 같이 이용정보를 저장하고 수시로
                              불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는
                              웹사이트를 운영하는데 이용되는 서버가 이용자의
                              컴퓨터 브라우저에게 보내는 소량의 정보이며
                              이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도
                              합니다.
                              <span className="indent">
                                1. 쿠키의 사용목적: 이용자의 접속 빈도나 방문
                                시간 등을 분석, 이용자의 서비스 사용 패턴 파악
                                및 자취 추적, 보안 접속 여부, 이용자 규모 등을
                                통해 보안 관리 및 서비스 개선 및 신규 서비스
                                개발, 맞춤형 서비스 및 광고 제공에 이용합니다.
                                <br />
                                2. 쿠키의 설치∙운영 및 거부: 서비스 이용자는
                                쿠키 설치에 대한 선택권을 가지고 있습니다.
                                따라서, 이용자는 웹 브라우저에서 옵션의 설정을
                                변경함으로써 쿠키의 저장을 거부할 수 있습니다.
                                <br />
                                3. 쿠키 저장을 거부할 경우 일부 서비스 이용에
                                어려움이 발생할 수 있습니다.
                              </span>
                            </dd>
                          </dl>
                          <dl>
                            <dt>12. 개인정보 보호책임자</dt>
                            <dd>
                              ① 회사는 개인정보 처리에 관한 업무를 총괄해서
                              책임지고, 개인정보 처리와 관련한 이용자의 불만처리
                              및 피해구제 등을 위하여 아래와 같이 개인정보
                              보호책임자를 지정하고 있습니다.
                              <span className="indent">
                                - 개인정보 보호 책임자
                                <br />- 성명: 장근혁
                                <br />- 직책: CPO
                                <br />- 연락처: 02-549-2233,
                                hd_privacy@thehyundai.com
                              </span>
                              ② 이용자는 회사의 서비스(또는 사업)을 이용하시면서
                              발생한 모든 개인정보 보호 관련 문의, 불만처리,
                              피해구제 등에 관한 사항을 개인정보 보호책임자 및
                              아래 담당부서로 문의하실 수 있습니다.
                              <span className="indent">
                                - 부서명: 현대백화점 정보보호담당
                                <br />- 연락처: 02-549-2233,
                                privacy2233@thehyundai.com
                              </span>
                            </dd>
                          </dl>
                          <dl>
                            <dt>13. 개인정보 처리방침의 변경</dt>
                            <dd>
                              회사는 법률이나 서비스의 변경사항을 반영하기 위한
                              목적 등으로 개인정보처리방침을 수시로 변경할 수
                              있습니다. 따라서 웹페이지를 방문하실 때마다 이를
                              확인하시기 바랍니다. 본 “처리방침”이 관련 법령 및
                              정책 또는 보안기술의 변경에 따라 내용의 추가 삭제
                              및 수정이 있을 경우 변경되는 “처리방침”을 시행하기
                              전에 H.Point 홈페이지를 통해 변경 사유 및 내용
                              등을 공지합니다.
                            </dd>
                          </dl>
                          <dl>
                            <dt />
                            <dd>
                              <b>
                                이 개인정보 처리방침은 2023. 3. 2(목)부터
                                적용됩니다.
                              </b>
                            </dd>
                          </dl>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* /.card */}
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /. row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
};

export default Polocy;
