import React, { useState, useEffect } from "react";
import { userState } from "@app/interface/MainInterface";
import { useLocation } from "react-router-dom";
import { ContentHeader } from "@components";
import {
  Button,
  Container,
  Form,
  Row,
  Col,
  InputGroup,
  Collapse,
  Table,
} from "react-bootstrap";
import QnABox from "@app/components/qna-box/QnABox";
import QnAQuestionBox from "@app/components/qna-box/QnAQuestionBox";
import QnAAnswerBox from "@app/components/qna-box/QnAAnswerBox";
import { HttpAdminMainApi } from "@app/interface/admin-main-api";
import { useNavigate } from "react-router-dom";

interface propsType {
  userState: userState;
}

interface detailType {
  content: string;
  answer_yn: boolean;
  create_date: string;
}

interface PostAnswer {
  aseq: string;
  content: string;
  oriseq: string;
  userState: userState;
}

interface titleType {
  title: string;
  answer: string;
  author: string;
  askTime: string;
}
// 직접 클릭하고 들어갔을때의 디테일한 박스
// props로 id, range를 받아야 함
// 여기에 들어가는 정보는
// 질문자의 정보박스와 답변자의 정보 박스를 넣어줘야 할 듯!
// 질문정보 + 답변 정보
// 답변정보는 box 형태로 가져오기

const QnAAdminDetail = () => {
  let { state } = useLocation();
  const [userState, setUserState] = useState(state.userState);

  const [questionDetail, setQuestionDetail] = useState([]);

  const [answerDetail, setAnswerDetail] = useState([]);

  const adminApi = new HttpAdminMainApi();

  const [totalDetailList, setTotalDetailList] = useState([]);

  const [checkReQuestion, setCheckRequestion] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [ansContent, setAnsContent] = useState("");

  const titleItem: any = state.item;
  const titleInfo: titleType = {
    title: titleItem.TITLE,
    answer: titleItem.ANSWER_YN,
    author: titleItem.AUTHOR,
    askTime: titleItem.CREATE_DATE,
  };

  const navigate = useNavigate();

  // 이제 여기서 가져옴
  const getQuestionDetail = async (qseq: string) => {
    const res = await adminApi.get_my_quetion_detail(qseq);
    if ("" + res.code === "200") {
      setQuestionDetail(res.response.question_detail_list);
    } else {
      alert("질문 불러오기에 실패하였습니다! 이전 페이지로 이동합니다");
      navigate("/qnaAdmin");
    }
  };
  // 그 다음 답변들을 가져오기

  const getAnswerDetail = async (qseq: string) => {
    const res = await adminApi.get_my_answer_detail(qseq);
    if ("" + res.code === "200") {
      setAnswerDetail(res.response.answer_detail_list);
    } else {
      alert("답변 불러오기에 실패하였습니다! 이전 페이지로 이동합니다!");
      navigate("/qnaAdmin");
    }
  };

  const combineDetailList = (q_detail: any[], a_detail: any[]) => {
    const convert_q_list = q_detail.map((item: any) => {
      return {
        content: item.CONTENT,
        answer_yn: false,
        create_date: item.CREATE_DATE,
      };
    });
    const convert_a_list = a_detail.map((item: any) => {
      return {
        content: item.CONTENT,
        answer_yn: true,
        create_date: item.CREATE_DATE,
      };
    });
    let tempTotalDetailList: any[] = [...convert_q_list, ...convert_a_list];
    tempTotalDetailList.sort((a: any, b: any) => {
      if (a.create_date > b.create_date) {
        return 1;
      } else if (a.create_date === b.create_date) {
        return 0;
      } else {
        return -1;
      }
    });
    // console.log(tempTotalDetailList);
    setTotalDetailList([...tempTotalDetailList]);
  };

  const qnaDetailList = (totalDetailList: detailType[]) => {
    const detailRenderingResult: any[] = totalDetailList.map(
      (item: any, index: number) => {
        if (item.answer_yn) {
          return (
            <QnAAnswerBox
              key={index}
              answer={item.content}
              date={item.create_date.split("/")[0]}
            />
          );
        } else {
          return <QnAQuestionBox key={index} question={item.content} />;
        }
      }
    );
    return detailRenderingResult;
  };

  // 추가 문의 남기기
  const handleContentChange = (e: any) => {
    setAnsContent(e.target.value);
  };

  const postAnswer = async () => {
    let today = new Date();
    const aseq = `${today.getDay()}${
      userState.id
    }${today.getMilliseconds()}${today.getMinutes()}A${
      totalDetailList.length + 1
    }`;
    const answerData: PostAnswer = {
      oriseq: titleItem.QSEQ,
      aseq,
      content: ansContent,
      userState,
    };

    const res = await adminApi.post_answer(answerData);
    if ("" + res.code === "200") {
      if (res.msg === "success") {
        alert("답변 작성을 완료하였습니다!");
      } else {
        // console.log("답변 작성 실패");
        alert("답변 작성을 실패하였습니다!");
      }
    } else {
      alert("답변 작성을 실패하였습니다!");
    }
    // qna 목록 페이지로 이동
    navigate("/qnaAdmin");
  };

  // 로그인 정보 없으면 로그인 페이지로 이동
  useEffect(() => {
    if (!state.userState.id) {
      window.location.href = "/login";
    }
    // console.log("titleItem : " + JSON.stringify(titleItem));
    // 여기서 로직을 가져옴
    getQuestionDetail(titleItem.QSEQ);
    getAnswerDetail(titleItem.QSEQ);
  }, []); // 처음 렌더링 될때 사용

  useEffect(() => {
    combineDetailList(questionDetail, answerDetail);
    // 모두 조회 완료되면 추가 문의 등록 버튼 활성화
    setIsLoading(true);
  }, [questionDetail, answerDetail]);

  useEffect(() => {
    if (totalDetailList.length > 0) {
      const lastData: detailType = totalDetailList[totalDetailList.length - 1];
      if (lastData.answer_yn) {
        setCheckRequestion(true);
      } else {
        setCheckRequestion(false);
      }
    }
  }, [totalDetailList]);

  return (
    <div>
      <ContentHeader title="문의" />
      <section className="content">
        <Container fluid>
          <Row>
            <div className="col-md-12 pd-0 mb-4">
              <div className="card card-primary card-outline">
                {/* 헤더 */}
                <div className="card-header">
                  <Row>
                    <Col>
                      <i className="fas fa-edit cont-tit qna"></i>
                      <h1 className="card-title">문의 답변 페이지</h1>
                    </Col>
                  </Row>
                </div>
                <div className="card-body pad table-responsive bg-box">
                  <div className="qna-cont">
                    <div className="input-wrap">
                      <div className="form-group qna-list">
                        <h4 className="case-num">문의 내용 확인</h4>
                      </div>

                      <div
                        className="form-group qna-answer-wrap"
                        style={{ overflow: "auto" }}
                      >
                        <Table>
                          <thead>
                            <QnABox titleInfo={titleInfo} />
                          </thead>
                          <tbody>
                            {totalDetailList.length > 0 ? (
                              qnaDetailList(totalDetailList)
                            ) : (
                              <tr>
                                <td colSpan={4}>no data</td>
                              </tr>
                            )}
                            {/* 답변등록 */}
                            <tr>
                              <td colSpan={4}>
                                <div className="more-write-wrap">
                                  <Form.Control
                                    as="textarea"
                                    className="form-control form-control-lg"
                                    rows={2}
                                    style={{ overflow: "visible" }}
                                    placeholder="답변 내용을 입력해주세요"
                                    onChange={handleContentChange}
                                  />
                                  <p className="send-btn-wrap"></p>
                                </div>
                                {isLoading ? (
                                  <button
                                    className="more-q"
                                    onClick={postAnswer}
                                  >
                                    답변 등록
                                  </button>
                                ) : (
                                  <div>잠시 기다려 주세요.</div>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default QnAAdminDetail;
