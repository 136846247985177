import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MenuItem } from "@components";
import { PfImage } from "@profabric/react-components";
import styled from "styled-components";
import i18n from "@app/utils/i18n";
import { userState } from "@app/interface/MainInterface";
import { Button } from "react-bootstrap";

interface propsType {
  userState: userState;
}

export enum MenuItemLevel {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
}

export interface IMenuItem {
  name: string;
  icon?: string;
  path?: string;
  children?: Array<IMenuItem>;
  level?: MenuItemLevel;
}

export const MENU: IMenuItem[] = [
  {
    name: "카피라이터",
    icon: "fas fa-tachometer-alt nav-icon",
    path: "/copy03",
    level: 5,
  },
  {
    name: "MYPAGE",
    icon: "fas fa-th nav-icon",
    children: [
      {
        name: "나의 카피 모음",
        icon: "fas fa-circle nav-icon",
        path: "/mypage01",
        level: 5,
      },

      {
        name: "좋아요 모음",
        icon: "fas fa-circle nav-icon",
        path: "/mypage02",
        level: 5,
      },

      {
        name: "모든 카피 모음",
        icon: "fas fa-circle nav-icon",
        path: "/mypage03",
        level: 2,
      },

      {
        name: "문의내역",
        icon: "fas fa-circle nav-icon",
        path: "/qna/list/1",
        level: 2,
      },
      // {
      //   name: "문의관리",
      //   icon: "fas fa-circle nav-icon",
      //   path: "/qnaAdmin",
      //   level: 2,
      // },
    ],
    level: 5,
  },
];

const StyledUserImage = styled(PfImage)`
  --pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
`;

const MenuSidebar = (props: propsType) => {
  const menuItemFlat = useSelector((state: any) => state.ui.menuItemFlat);
  const menuChildIndent = useSelector((state: any) => state.ui.menuChildIndent);
  const userLevel = parseInt(props.userState.userType);

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4 mainbody">
      {/* Brand Logo */}
      <a href="/copy03" className="brand-link">
        <img
          src="/dist/images/logo-mini.png"
          alt="Logo"
          className="brand-image elevation-3"
        />
        <span className="brand-text font-weight-light">
          <img src="/dist/images/logo-txt.png" />
        </span>
      </a>
      {/* Sidebar */}
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="/dist/images/user2-160x160.jpg"
              className="img-circle elevation-2"
            ></img>
          </div>
          <div className="info">
            <dl>
              <dt>{props.userState.userName}</dt>
              <dd>
                {props.userState.userInfo2}&nbsp;{props.userState.userInfo4}
              </dd>
            </dl>
          </div>
        </div>
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul
            className={`nav nav-pills nav-sidebar flex-column${
              menuItemFlat ? " nav-flat" : ""
            }${menuChildIndent ? " nav-child-indent" : ""}`}
            role="menu"
          >
            {MENU.map((menuItem: IMenuItem) => {
              if (menuItem.level >= userLevel) {
                return (
                  <MenuItem
                    key={menuItem.name + menuItem.path}
                    menuItem={menuItem}
                  />
                );
              }
              return null;
            })}
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
};

export default MenuSidebar;
