import { GetLikeInfoParam, HttpAdminMainApi, PutLikeInfoParam } from '@app/interface/admin-main-api';
import React, { useState, useEffect } from 'react'
import { userState, inputList } from "@app/interface/MainInterface";

interface propsType {
    pk: string;
    range: string;
    category: string;
    subRange: string;
    userState: userState;
    inputList: inputList;
}

export default function LikeClick(props: propsType) {
    const adminApi = new HttpAdminMainApi();
    const [classData, setclassData] = useState('fas fa-icon fa-thumbs-up');
    const setClickLike = async () => {
        const param: PutLikeInfoParam = {
            pk: props.pk,
            range: props.range,
            category: props.category,
            subRange: props.subRange,
            userState: props.userState,
            inputList: props.inputList
        };
        const res = await adminApi.put_like(param);
        if ("" + res.code === "200") {
            res.response.result === "Y"
                ? setclassData("fas fa-icon fa-thumbs-up active")
                : setclassData("fas fa-icon fa-thumbs-up")
        } else {
            console.error("setClickLike ERROR : ", res.response.error_msg);
        }
    };

    const get_like_info = async () => {
        const param: GetLikeInfoParam = {
            userState: props.userState,
            range: props.range
        };
        const res = await adminApi.get_like_info(param);
        if ("" + res.code === "200") {
            res.response.result === "Y"
                ? setclassData("fas fa-icon fa-thumbs-up active")
                : setclassData("fas fa-icon fa-thumbs-up")
        } else {
            console.error("get_like_info ERROR : ", res.response.error_msg);
        }
    };

    useEffect(() => {
        get_like_info();
    }, []) //[] 변경시마다  작동

    return (
        <i key={props.range} className={classData} onClick={setClickLike} />
    )


}
